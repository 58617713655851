import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Form, Row, Col, Modal, Table } from 'react-bootstrap';
import moment from 'moment';
//import equipos from '../utils/equipos'; // Archivo de equipos
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import campos from '../utils/campos'; // Archivo de campos
import categorias from '../utils/categorias'; // Archivo de categorías

const Partidos = () => {
  const [partidos, setPartidos] = useState([]);
  const [torneos, setTorneos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showBateoModal, setShowBateoModal] = useState(false);
  const [bateoStats, setBateoStats] = useState([]);
  const [showPitcheoModal, setShowPitcheoModal] = useState(false);
  const [pitcheoStats, setPitcheoStats] = useState([]);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [currentFeedback, setCurrentFeedback] = useState({ jugadorID: null, partidoID: null, texto: '', publico: false });
  const [equipos, setEquipos] = useState([]);
  const [user, setUser] = useState(null);

  const [filters, setFilters] = useState({
    Categoria: '',
    Rival: '',
    Fecha: '',
    TorneoID: ''
  });
  const [nuevoPartido, setNuevoPartido] = useState({
    TorneoID: '',
    Fecha: '',
    Rival: '',
    Campo: '',
    Categoria: '',
    LocalVisita: 'Local',
    CarrerasAFavor: '',
    CarrerasEnContra: '',
    Arbitro: '',
  });

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const fetchJugadorLogueado = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, authHeader);
        setUser(response.data); 
        
      } catch (error) {
        console.error('Error al obtener los datos del jugador logueado', error);
      }
    };

    fetchJugadorLogueado();
  }, []);

  //const isCoachOrAdmin = user && (user.role === 'coach' || user.role === 'admin');
  
  useEffect(() => {
    fetchPartidos();
    fetchTorneos();
    fetchEquipos();
  }, []);

  

  const fetchPartidos = async () => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/partidos`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const sortedPartidos = response.data.sort(
        (a, b) => new Date(b.Fecha) - new Date(a.Fecha)
      );
      setPartidos(sortedPartidos);
    } catch (error) {
      console.error('Error fetching partidos:', error);
    }
  };

  const fetchTorneos = async () => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/torneos`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setTorneos(response.data);
    } catch (error) {
      console.error('Error fetching torneos:', error);
    }
  };

  const fetchEquipos = async () => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/equipos`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      const equiposData = Array.isArray(response.data) ? response.data : [response.data];
      const equiposOrdenados = equiposData.sort((a, b) => a.Equipo.localeCompare(b.Equipo));

      setEquipos(equiposOrdenados);
    } catch (error) {
      console.error('Error al obtener los equipos:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoPartido((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSavePartido = async () => {
    const token = localStorage.getItem('jwt');
    try {
      if (nuevoPartido.id) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/partidos/${nuevoPartido.id}`, nuevoPartido, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/partidos`, nuevoPartido, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }
      fetchPartidos();
      handleCloseModal();
    } catch (error) {
      console.error('Error saving partido:', error);
    }
  };

  const handleDeletePartido = async (partidoId) => {
    const token = localStorage.getItem('jwt');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/partidos/${partidoId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchPartidos();
    } catch (error) {
      console.error('Error deleting partido:', error);
    }
  };

  const handleEditPartido = (partido) => {
    setNuevoPartido(partido);
    setShowModal(true);
  };

  const fetchBateoStats = async (partidoID) => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/estadisticas/bateo/partido/${partidoID}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      const statsWithPartidoID = response.data.map((stat) => ({ ...stat, partidoID })); // Agrega el partidoID a cada stat
      setBateoStats(statsWithPartidoID); // Guarda las estadísticas con el partidoID
      setShowBateoModal(true);
    } catch (error) {
      console.error('Error al obtener estadísticas de bateo:', error);
    }
  };

  const fetchPitcheoStats = async (partidoID) => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/estadisticas/pitcheo/partido/${partidoID}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      const statsWithPartidoID = response.data.map((stat) => ({ ...stat, partidoID })); // Agrega el partidoID a cada stat
      setPitcheoStats(statsWithPartidoID); // Guarda las estadísticas con el partidoID
      setShowPitcheoModal(true);
    } catch (error) {
      console.error('Error al obtener estadísticas de bateo:', error);
    }
  };

  // Mostrar el modal de Feedback
  const handleShowFeedbackModal = (jugadorID, partidoID) => {
    console.log('JugadorID:', jugadorID, 'PartidoID:', partidoID);
    setCurrentFeedback({ jugadorID, partidoID, texto: '', publico: false });
    setShowFeedbackModal(true);
  };

  // Guardar Feedback
  const handleSaveFeedback = async () => {
    const token = localStorage.getItem('jwt');
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/feedbacks/crear`,
        {
          JugadorID: currentFeedback.jugadorID,
          PartidoID: currentFeedback.partidoID,
          Texto: currentFeedback.texto,
          Publico: currentFeedback.publico ? 'Y' : 'N',
          //CoachID: 1, // Reemplazar con ID real del coach logueado
          CoachID: user.Jugador.id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert('Feedback guardado correctamente.');
      setShowFeedbackModal(false);
    } catch (error) {
      console.error('Error al guardar feedback:', error);
    }
  };
  

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setNuevoPartido({
      TorneoID: '',
      Fecha: '',
      Rival: '',
      Campo: '',
      Categoria: '',
      LocalVisita: 'Local',
      CarrerasAFavor: '',
      CarrerasEnContra: '',
      Arbitro: '',
    });
    setShowModal(false);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const filteredPartidos = partidos.filter((partido) => {
    return (
      (filters.Categoria ? String(partido.Categoria) === String(filters.Categoria) : true) &&
      (filters.Rival ? String(partido.Rival) === String(filters.Rival) : true) &&
      (filters.Fecha ? moment(partido.Fecha).isSame(filters.Fecha, 'day') : true) &&
      (filters.TorneoID ? partido.TorneoID === parseInt(filters.TorneoID) : true)
    );
  });

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadFile = async (torneoID, partidoID) => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);
    const token = localStorage.getItem('jwt');

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/estadisticasBateo/upload?torneoID=${torneoID}&partidoID=${partidoID}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Archivo cargado correctamente');
      window.location.href = `/match-jugadores/${torneoID}/${partidoID}`;
    } catch (error) {
      console.error('Error al cargar el archivo:', error);
    }
  };

  const handleUploadFilePitcheo = async (torneoID, partidoID) => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);
    const token = localStorage.getItem('jwt');

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/estadisticasPitcheo/upload?torneoID=${torneoID}&partidoID=${partidoID}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Archivo cargado correctamente');
      window.location.href = `/match-jugadores-pitcheo/${torneoID}/${partidoID}`;
    } catch (error) {
      console.error('Error al cargar el archivo:', error);
    }
  };

  return (
    <div>
      <h1>Administrar Partidos</h1>
      <Button variant="primary" onClick={handleShowModal}>
        Agregar Nuevo Partido
      </Button>

      {/* Filtros */}
      <Form className="my-4">
        <Row>
          <Col>
            <Form.Group controlId="filterCategoria">
              <Form.Label>Categoría</Form.Label>
              <Form.Control as="select" name="Categoria" value={filters.Categoria} onChange={handleFilterChange}>
                <option value="">Todas las categorías</option>
                {Object.entries(categorias).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="filterRival">
              <Form.Label>Rival</Form.Label>
              <Form.Control as="select" name="Rival" value={filters.Rival} onChange={handleFilterChange}>
              <option value="">Selecciona un rival</option>
              {equipos.map((equipo) => (
                <option key={equipo.ID} value={equipo.ID}>
                  {equipo.Equipo} {/* Muestra el nombre del equipo */}
                </option>
              ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="filterFecha">
              <Form.Label>Fecha</Form.Label>
              <Form.Control type="date" name="Fecha" value={filters.Fecha} onChange={handleFilterChange} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="filterTorneo">
              <Form.Label>Torneo</Form.Label>
              <Form.Control as="select" name="TorneoID" value={filters.TorneoID} onChange={handleFilterChange}>
                <option value="">Todos los torneos</option>
                {torneos.map((torneo) => (
                  <option key={torneo.id} value={torneo.id}>
                    {torneo.Nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      {/* Modal para agregar o editar un partido */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{nuevoPartido.id ? 'Editar Partido' : 'Agregar Nuevo Partido'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTorneoID">
              <Form.Label>Torneo</Form.Label>
              <Form.Control
                as="select"
                name="TorneoID"
                value={nuevoPartido.TorneoID}
                onChange={handleInputChange}
              >
                <option value="">Selecciona un torneo (opcional)</option>
                {torneos.map((torneo) => (
                  <option key={torneo.id} value={torneo.id}>
                    {torneo.Nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formFecha">
              <Form.Label>Fecha</Form.Label>
              <Form.Control
                type="date"
                name="Fecha"
                value={nuevoPartido.Fecha}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formRival">
              <Form.Label>Rival</Form.Label>
              <Form.Control
                as="select"
                name="Rival"
                value={nuevoPartido.Rival}
                onChange={handleInputChange}
              >
                <option value="">Selecciona un rival</option>
                {equipos.map((equipo) => (
                  <option key={equipo.ID} value={equipo.ID}>
                    {equipo.Equipo}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCampo">
              <Form.Label>Campo</Form.Label>
              <Form.Control
                as="select"
                name="Campo"
                value={nuevoPartido.Campo}
                onChange={handleInputChange}
              >
                <option value="">Selecciona un campo</option>
                {Object.entries(campos).map(([key, campo]) => (
                  <option key={key} value={key}>
                    {campo.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCategoria">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                as="select"
                name="Categoria"
                value={nuevoPartido.Categoria}
                onChange={handleInputChange}
              >
                <option value="">Selecciona una categoría</option>
                {Object.entries(categorias).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formLocalVisita">
              <Form.Label>Local/Visita</Form.Label>
              <Form.Control
                as="select"
                name="LocalVisita"
                value={nuevoPartido.LocalVisita}
                onChange={handleInputChange}
              >
                <option value="Local">Local</option>
                <option value="Visita">Visita</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formPlayoff" className="mt-3">
              <Form.Check
                type="checkbox"
                label="Playoff"
                name="Playoff"
                checked={nuevoPartido.Playoff === 'Y'}
                onChange={(e) =>
                  setNuevoPartido({
                    ...nuevoPartido,
                    Playoff: e.target.checked ? 'Y' : 'N',
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formCarrerasAFavor">
              <Form.Label>Carreras a Favor</Form.Label>
              <Form.Control
                type="number"
                name="CarrerasAFavor"
                value={nuevoPartido.CarrerasAFavor}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formCarrerasEnContra">
              <Form.Label>Carreras en Contra</Form.Label>
              <Form.Control
                type="number"
                name="CarrerasEnContra"
                value={nuevoPartido.CarrerasEnContra}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formArbitro">
              <Form.Label>Árbitro</Form.Label>
              <Form.Control
                type="text"
                name="Arbitro"
                value={nuevoPartido.Arbitro}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSavePartido}>
            {nuevoPartido.id ? 'Actualizar Partido' : 'Guardar Partido'}
          </Button>
        </Modal.Footer>
      </Modal>
      
      {/* Modal para ver el bateo */}
      <Modal show={showBateoModal} onHide={() => setShowBateoModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Estadísticas de Bateo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {bateoStats.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>AB</th>
                  <th>R</th>
                  <th>H</th>
                  <th>BB</th>
                  <th>RBI</th>
                  <th>SO</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {bateoStats.map((stat, index) => (
                  <tr key={index}>
                    <td>{stat.nombre}</td>
                    <td>{stat.apellido}</td>
                    <td>{stat.AB}</td>
                    <td>{stat.R}</td>
                    <td>{stat.H}</td>
                    <td>{stat.BB}</td>
                    <td>{stat.RBI}</td>
                    <td>{stat.SO}</td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleShowFeedbackModal(stat.jugadorID, stat.partidoID)}
                      >
                        Agregar Feedback
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No hay estadísticas de bateo disponibles para este partido.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBateoModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para ver el pitcheo */}
      <Modal show={showPitcheoModal} onHide={() => setShowPitcheoModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Estadísticas de Pitcheo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {pitcheoStats.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>IP</th>
                  <th>R</th>
                  <th>ER</th>
                  <th>BB</th>
                  <th>K</th>
                  <th>H</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {pitcheoStats.map((stat, index) => (
                  <tr key={index}>
                    <td>{stat.nombre}</td>
                    <td>{stat.apellido}</td>
                    <td>{stat.IP}</td>
                    <td>{stat.R}</td>
                    <td>{stat.ER}</td>
                    <td>{stat.BB}</td>
                    <td>{stat.K}</td>
                    <td>{stat.H}</td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleShowFeedbackModal(stat.jugadorID, stat.partidoID)}
                      >
                        Agregar Feedback
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No hay estadísticas de pitcheo disponibles para este partido.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPitcheoModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para agregar Feedback */}
      <Modal show={showFeedbackModal} onHide={() => setShowFeedbackModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="feedbackTexto">
              <Form.Label>Texto del Feedback</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={currentFeedback.texto}
                onChange={(e) => setCurrentFeedback({ ...currentFeedback, texto: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="feedbackPublico" className="mt-3">
              <Form.Check
                type="checkbox"
                label="Hacer público"
                checked={currentFeedback.publico}
                onChange={(e) => setCurrentFeedback({ ...currentFeedback, publico: e.target.checked })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFeedbackModal(false)}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleSaveFeedback}>
            Guardar Feedback
          </Button>
        </Modal.Footer>
      </Modal>


      {/* Listado de Partidos */}
      <Card>
        <Card.Header>Partidos</Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Categoría</th>
                <th>VS</th>
                <th>Fecha</th>
                <th>Resultado</th>
                <th>Campo</th>
                <th>Arbitro</th>
                <th>Torneo</th>
                <th>Playoff</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filteredPartidos.map((partido) => {
                const resultadoColor = partido.CarrerasAFavor > partido.CarrerasEnContra
                  ? '#d4edda'  // Verde claro (victoria)
                  : partido.CarrerasAFavor < partido.CarrerasEnContra
                  ? '#f8d7da'  // Rojo claro (derrota)
                  : 'transparent';  // Sin color si son iguales o vacíos

                const playoffColor = partido.Playoff === 'Y' ? '#cce5ff' : 'transparent';  // Azul si es Playoff


                return (
                
                <tr key={partido.id}>
                  <td>{categorias[partido.Categoria]}</td>
                  <td>{equipos.find((equipo) => equipo.ID === partido.Rival)?.Equipo || 'Desconocido' }</td>
                  <td>{moment(partido.Fecha).format('DD/MM/YYYY')}</td>
                  <td style={{ backgroundColor: resultadoColor }}>{partido.CarrerasAFavor} - {partido.CarrerasEnContra}</td>
                  <td>{partido.Campo}</td>
                  <td>{partido.Arbitro}</td>
                  <td>{torneos.find((t) => t.id === partido.TorneoID)?.Nombre || 'N/A'}</td>
                  <td style={{ backgroundColor: playoffColor }}>
                    <Form.Check
                      type="checkbox"
                      checked={partido.Playoff === 'Y'}
                      readOnly
                    />
                  </td>
                  <td>
                    <Button variant="warning" onClick={() => handleEditPartido(partido)} className="me-2">
                      <FaEdit />
                    </Button>
                    <Button
                      variant="info"
                      onClick={() => fetchBateoStats(partido.id)}
                      className="me-2"
                    >
                      Bateo
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => fetchPitcheoStats(partido.id)}
                      className="me-2"
                    >
                      Pitcheo
                    </Button>
                    
                    <Button
                      variant="primary"
                      onClick={() => setShowFileUpload(partido.id)}
                    >
                      Cargar
                    </Button> 
                    {' '}
                    <Button variant="danger" onClick={() => handleDeletePartido(partido.id)} className="me-2">
                      <FaTrashAlt />
                    </Button>
                    {showFileUpload === partido.id && (
                      <>
                        <Form.Group controlId={`upload-file-${partido.id}`} className="mt-2">
                          <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Button
                          className="mt-2"
                          variant="info"
                          onClick={() => handleUploadFile(partido.TorneoID, partido.id)}
                        >
                          Cargar Stats Bateo
                        </Button>
                        {' '}
                        <Button
                          className="mt-2"
                          variant="secondary"
                          //onClick={() => alert('Funcionalidad de Cargar Stats Pitcheo aún no implementada')}
                          onClick={() => handleUploadFilePitcheo(partido.TorneoID, partido.id)}
                        >
                          Cargar Stats Pitcheo
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              )})}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Partidos;
