import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Row, Col, Form, Modal } from 'react-bootstrap';
import banderas from '../utils/banderas';
import getCategorias from '../utils/categoriasBD';
import equipos from '../utils/equipos';

const AdminPlayer = () => {
  const [players, setPlayers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const [categorias, setCategorias] = useState([]);
    

  useEffect(() => {
    // Obtener lista de jugadores
    const fetchPlayers = async () => {
      try {
        const token = localStorage.getItem('jwt');
        const authHeader = {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores`, authHeader);
        setPlayers(response.data);
      } catch (error) {
        console.error('Error al obtener la lista de jugadores:', error);
      }
    };
    fetchData();
    fetchPlayers();
  }, []);

  
  const fetchData = async () => {
    const categorias = await getCategorias();
    setCategorias(categorias);
  };

  const handleStatusChange = async (playerId, newStatus) => {
    try {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      await axios.post(`${process.env.REACT_APP_API_URL}/api/jugadores/update-status`, {
        playerId,
        newStatus
      }, authHeader);
      alert('Estado actualizado correctamente');
      // Volver a cargar la lista de jugadores
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores`, authHeader);
      setPlayers(response.data);
    } catch (error) {
      console.error('Error al actualizar el estado:', error);
    }
  };

  const handleCreatePlayer = async (playerData) => {
    try {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      await axios.post(`${process.env.REACT_APP_API_URL}/api/jugadores/create`, playerData, authHeader);
      setShowCreateModal(false);
      // Recargar jugadores
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores`, authHeader);
      setPlayers(response.data);
    } catch (error) {
      console.error('Error al crear jugador:', error);
    }
  };

  const handleEditPlayer = (player) => {
    setSelectedPlayer(player);
    setShowEditModal(true);
  };

  const handleUpdatePlayer = async (playerData) => {
    try {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      await axios.put(`${process.env.REACT_APP_API_URL}/api/jugadores/update/${playerData.id}`, playerData, authHeader);
      setShowEditModal(false);
      // Recargar jugadores
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores`, authHeader);
      setPlayers(response.data);
    } catch (error) {
      console.error('Error al actualizar jugador:', error);
    }
  };

  const handleCloseModal = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    setSelectedPlayer({
      nombre: '',
      apellido: '',
      nacimiento: '',
      nacionalidad: '',
      genero: 'M',
      casaca: '',
      dni: '',
      socio: '',
      categoria: '',
      lanza: 'D',
      batea: 'D',
      inicio: new Date().toISOString().split('T')[0],
      equipoOrigen: '',
      motivoInicio: '',
      telefono: '',
      mail: '',
      tipoArancel: 'F'
    });
  };

  // Filtrar jugadores por el término de búsqueda
  const filteredPlayers = players.filter(player =>
    (player.nombre ? player.nombre.toLowerCase() : '').includes(searchTerm.toLowerCase()) ||
    (player.apellido ? player.apellido.toLowerCase() : '').includes(searchTerm.toLowerCase()) ||
    (player.casaca ? player.casaca.toString() : '').includes(searchTerm)
  );

  return (
    <div>
      <h1>Administrar Jugadores</h1>
      <input
        type="text"
        placeholder="Buscar jugador..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="form-control mb-3"
      />
      <Button variant="primary" onClick={() => setShowCreateModal(true)}>
        Crear nuevo jugador
      </Button>

      <Row>
        {filteredPlayers.length > 0 ? (
          filteredPlayers.map((player) => (
            <Col key={player.id} md={4} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title>{player.nombre} {player.apellido}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {player.nacionalidad && (
                      <img
                        src={banderas[player.nacionalidad].url}
                        alt={player.nacionalidad}
                        style={{ width: '30px', height: '20px', marginRight: '10px' }}
                      />
                    )}
                  </Card.Subtitle>
                  <Card.Text>
                    Casaca: {player.casaca || 'No asignada'}<br />
                    Estado: {player.estado === 'Y' ? 'Activo' : 'Inactivo'}
                  </Card.Text>
                  <Button
                    variant={player.estado === 'Y' ? 'danger' : 'success'}
                    onClick={() => handleStatusChange(player.id, player.estado === 'Y' ? 'N' : 'Y')}
                  >
                    {player.estado === 'Y' ? 'Marcar como Inactivo' : 'Marcar como Activo'}
                  </Button>
                  <Button variant="warning" className="ms-2" onClick={() => handleEditPlayer(player)}>
                    Editar
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p>No se encontraron jugadores</p>
        )}
      </Row>

      <Modal show={showCreateModal || showEditModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{showCreateModal ? 'Crear Jugador' : 'Editar Jugador'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Nombre */}
            <Form.Group controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                defaultValue={selectedPlayer?.nombre || ''}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, nombre: e.target.value })}
              />
            </Form.Group>

            {/* Apellido */}
            <Form.Group controlId="apellido" className="mt-3">
              <Form.Label>Apellido</Form.Label>
              <Form.Control
                type="text"
                defaultValue={selectedPlayer?.apellido || ''}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, apellido: e.target.value })}
              />
            </Form.Group>

            {/* Nacimiento */}
            <Form.Group controlId="nacimiento" className="mt-3">
              <Form.Label>Nacimiento</Form.Label>
              <Form.Control
                type="date"
                defaultValue={selectedPlayer?.nacimiento || ''}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, nacimiento: e.target.value })}
              />
            </Form.Group>

            {/* Nacionalidad */}
            <Form.Group controlId="nacionalidad" className="mt-3">
              <Form.Label>Nacionalidad</Form.Label>
              <Form.Control
                as="select"
                defaultValue={selectedPlayer?.nacionalidad || ''}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, nacionalidad: e.target.value })}
              >
                {Object.keys(banderas).map((key) => (
                  <option key={key} value={key}>
                    <img src={banderas[key].url} alt={banderas[key].nombre} style={{ width: '30px', marginRight: '10px' }} />
                    {banderas[key].nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Género */}
            <Form.Group controlId="genero" className="mt-3">
              <Form.Label>Género</Form.Label>
              <Form.Check
                type="switch"
                id="genero-switch"
                label={selectedPlayer?.genero === 'M' ? 'Masculino' : 'Femenino'}
                checked={selectedPlayer?.genero === 'F'}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, genero: e.target.checked ? 'F' : 'M' })}
              />
            </Form.Group>

            {/* Casaca */}
            <Form.Group controlId="casaca" className="mt-3">
              <Form.Label>Casaca</Form.Label>
              <Form.Control
                type="number"
                defaultValue={selectedPlayer?.casaca || ''}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, casaca: e.target.value })}
              />
            </Form.Group>

            {/* DNI */}
            <Form.Group controlId="dni" className="mt-3">
              <Form.Label>DNI</Form.Label>
              <Form.Control
                type="number"
                defaultValue={selectedPlayer?.dni || ''}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, dni: e.target.value })}
              />
            </Form.Group>

            {/* Socio */}
            <Form.Group controlId="socio" className="mt-3">
              <Form.Label>Socio</Form.Label>
              <Form.Control
                type="text"
                defaultValue={selectedPlayer?.socio || ''}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, socio: e.target.value })}
              />
            </Form.Group>

            {/* Categoría */}
            <Form.Group controlId="categoria" className="mt-3">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                as="select"
                defaultValue={selectedPlayer?.categoria || ''}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, categoria: e.target.value })}
              >
                {Object.entries(categorias).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Lanza */}
            <Form.Group controlId="lanza" className="mt-3">
              <Form.Label>Lanza</Form.Label>
              <Form.Check
                type="switch"
                label={selectedPlayer?.lanza === 'D' ? 'Derecha' : 'Izquierda'}
                checked={selectedPlayer?.lanza === 'Z'}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, lanza: e.target.checked ? 'Z' : 'D' })}
              />
            </Form.Group>

            {/* Batea */}
            <Form.Group controlId="batea" className="mt-3">
              <Form.Label>Batea</Form.Label>
              <Form.Check
                type="switch"
                label={selectedPlayer?.batea === 'D' ? 'Derecha' : 'Izquierda'}
                checked={selectedPlayer?.batea === 'Z'}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, batea: e.target.checked ? 'Z' : 'D' })}
              />
            </Form.Group>

            {/* Inicio */}
            <Form.Group controlId="inicio" className="mt-3">
              <Form.Label>Fecha de Inicio</Form.Label>
              <Form.Control
                type="date"
                defaultValue={selectedPlayer?.inicio || new Date().toISOString().split('T')[0]}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, inicio: e.target.value })}
              />
            </Form.Group>

            {/* Equipo de Origen */}
            <Form.Group controlId="equipoOrigen" className="mt-3">
              <Form.Label>Equipo de Origen</Form.Label>
              <Form.Control
                as="select"
                defaultValue={selectedPlayer?.equipoOrigen || ''}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, equipoOrigen: e.target.value })}
              >
                {Object.entries(equipos).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Motivo de Inicio */}
            <Form.Group controlId="motivoInicio" className="mt-3">
              <Form.Label>Motivo de Inicio</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                defaultValue={selectedPlayer?.motivoInicio || ''}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, motivoInicio: e.target.value })}
              />
            </Form.Group>

            {/* Teléfono */}
            <Form.Group controlId="telefono" className="mt-3">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                defaultValue={selectedPlayer?.telefono || ''}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, telefono: e.target.value })}
              />
            </Form.Group>

            {/* Email */}
            <Form.Group controlId="mail" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                defaultValue={selectedPlayer?.mail || ''}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, mail: e.target.value })}
              />
            </Form.Group>

            {/* Tipo de Arancel */}
            <Form.Group controlId="tipoArancel" className="mt-3">
              <Form.Label>Tipo de Arancel</Form.Label>
              <Form.Check
                type="switch"
                label={selectedPlayer?.tipoArancel === 'F' ? 'Federado' : 'Escuela'}
                checked={selectedPlayer?.tipoArancel === 'E'}
                onChange={(e) => setSelectedPlayer({ ...selectedPlayer, tipoArancel: e.target.checked ? 'E' : 'F' })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => showCreateModal ? handleCreatePlayer(selectedPlayer) : handleUpdatePlayer(selectedPlayer)}>
            {showCreateModal ? 'Crear' : 'Guardar Cambios'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>    
  );
};

export default AdminPlayer;



