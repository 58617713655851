import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Card, Form, Nav } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Line, Bar } from 'react-chartjs-2';
// import categorias from '../utils/categorias';
import tipoMedicion from '../utils/tipoMedicion';

const EquipoEstadisticas = () => {
  const { equipoId } = useParams();
  const [jugadores, setJugadores] = useState([]);
  const [estadisticas, setEstadisticas] = useState([]);
  const [mediciones, setMediciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('mediciones');
  const [tipoFiltro, setTipoFiltro] = useState('');
  const [modoFiltro, setModoFiltro] = useState('ultima'); // ultima o historico
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [torneoFiltro, setTorneoFiltro] = useState('');
  //const [modoAcumulado, setModoAcumulado] = useState(false);
  const [modoAcumuladoHistorico, setModoAcumuladoHistorico] = useState(false);


  

  const fetchEstadisticas = React.useCallback(async () => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const responseJugadores = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/equiposvelez/${equipoId}/jugadores`,
        authHeader
      );
      const responseMediciones = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/mediciones/equipo/${equipoId}`,
        authHeader
      );
      const responseEstadisticas = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/estadisticas/equipo/${equipoId}`,
        authHeader
      );

      setEstadisticas(responseEstadisticas.data);
      setJugadores(responseJugadores.data);
      console.log('mediciones response',responseMediciones.data)
      setMediciones(responseMediciones.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener estadísticas:', error);
    }
  }, [equipoId]);

  useEffect(() => {
    fetchEstadisticas();
  }, [fetchEstadisticas]);

  const [yearFiltro, setYearFiltro] = useState('');
  const handleTorneoFiltroChange = (e) => setTorneoFiltro(e.target.value);
  //const handleModoAcumuladoChange = (e) => setModoAcumulado(e.target.checked);
  const handleModoAcumuladoChange = (e) =>
    setModoAcumuladoHistorico(e.target.checked);
  const handleYearFiltroChange = (e) => setYearFiltro(e.target.value);

  // Filtrar o agrupar por torneo
  const filtrarEstadisticas = () => {
    let datosFiltrados = estadisticas;
  
    if (torneoFiltro) {
      datosFiltrados = estadisticas.filter(
        (est) => est.TorneoID === parseInt(torneoFiltro)
      );
    }
  
    // Filtrar por año si se selecciona un año
    if (yearFiltro) {
        datosFiltrados = datosFiltrados.filter((est) => {
          const torneoYear = est.FechaInicio
            ? new Date(est.FechaInicio).getFullYear()
            : null;
          return torneoYear === parseInt(yearFiltro);
        });
      }
  
      // Acumulado Histórico
      if (modoAcumuladoHistorico) {
        const acumulado = {};
      
        datosFiltrados.forEach((est) => {
          const jugadorID = est.JugadorID;
      
          if (!acumulado[jugadorID]) {
            acumulado[jugadorID] = { ...est };
          } else {
            acumulado[jugadorID].G = Number(acumulado[jugadorID].G || 0) + Number(est.G || 0);
            acumulado[jugadorID].AB = Number(acumulado[jugadorID].AB || 0) + Number(est.AB || 0);
            acumulado[jugadorID].H = Number(acumulado[jugadorID].H || 0) + Number(est.H || 0);
            acumulado[jugadorID].HR = Number(acumulado[jugadorID].HR || 0) + Number(est.HR || 0);
            acumulado[jugadorID].R = Number(acumulado[jugadorID].R || 0) + Number(est.R || 0);
            acumulado[jugadorID].RBI = Number(acumulado[jugadorID].RBI || 0) + Number(est.RBI || 0);
      
            acumulado[jugadorID].AVG =
              acumulado[jugadorID].AB > 0
                ? (acumulado[jugadorID].H / acumulado[jugadorID].AB).toFixed(3)
                : 0.000;
          }
        });
      
        return Object.values(acumulado);
      }
  
      return datosFiltrados;
    };

  const handleTabSelect = (tab) => setActiveTab(tab);

  const handleTipoFiltroChange = (e) => setTipoFiltro(e.target.value);

  const handleModoFiltroChange = (e) => setModoFiltro(e.target.value);

  const filtrarMediciones = () => {
    let datosFiltrados = mediciones;

    if (tipoFiltro) {
      datosFiltrados = mediciones.filter(
        (med) => med.TipoMedicion === parseInt(tipoFiltro)
      );
    }

    if (modoFiltro === 'historico') {
      const maximos = {};

      datosFiltrados.forEach((med) => {
        if (
          !maximos[med.IDJugador] ||
          med.Maximo > maximos[med.IDJugador].Maximo
        ) {
          maximos[med.IDJugador] = med;
        }
      });
      return Object.values(maximos);
    }

    if (modoFiltro === 'ultima') {
        const ultimas = {};
        datosFiltrados.forEach((med) => {
          if (
            !ultimas[med.IDJugador] ||
            new Date(med.Fecha) > new Date(ultimas[med.IDJugador].Fecha)
          ) {
            ultimas[med.IDJugador] = med;
          }
        });
        return Object.values(ultimas);
      }

    return datosFiltrados;
  };

  // Función para manejar el ordenamiento
const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };
  
  // Función para aplicar el ordenamiento a los datos
  const sortedMediciones = () => {
    let datos = [...filtrarMediciones()];
    if (sortConfig.key) {
      datos.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return datos;
  };

  const prepararDatosGraficoBarra = () => {
    const datosFiltrados = filtrarMediciones();

    const jugadoresConMediciones = jugadores.filter((jugador) => {
        const medicionJugador = datosFiltrados.find(
          (med) => med.IDJugador === jugador.id
        );
        return medicionJugador && medicionJugador.Maximo > 0;
      });
    
      // Generar colores dinámicos
      const colores = jugadoresConMediciones.map(
        () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
      );

    return {
      labels: jugadoresConMediciones.map((j) => `${j.nombre} ${j.apellido}`),
      datasets: [
        {
          label: 'Comparación de Mediciones',
          data: jugadoresConMediciones.map((jugador) => {
            const medicionJugador = datosFiltrados.find(
              (med) => med.IDJugador === jugador.id
            );
            return medicionJugador ? medicionJugador.Maximo : 0;
          }),
          backgroundColor: colores,
        },
      ],
    };
  };

  const prepararDatosGraficoLinea = () => {
    const datosFiltrados = mediciones.filter(
      (med) => tipoFiltro === '' || med.TipoMedicion === parseInt(tipoFiltro)
    );

    const data = jugadores.map((jugador) => {
      const medicionJugador = datosFiltrados.filter(
        (med) => med.IDJugador === jugador.id
      );

      return {
        label: `${jugador.nombre} ${jugador.apellido}`,
        data: medicionJugador.map((med) => ({ x: med.Fecha, y: med.Maximo })),
        borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        fill: false,
      };
    });

    return {
      //labels: jugadores.map((j) => `${j.nombre} ${j.apellido}`),
      datasets: data,
    };
  };

  return (
    <Container>
      <h1>Estadísticas del Equipo</h1>
      {loading ? (
        <p>Cargando estadísticas...</p>
      ) : (
        <>
          <Nav variant="tabs" activeKey={activeTab} onSelect={handleTabSelect}>
            <Nav.Item>
              <Nav.Link eventKey="mediciones">Mediciones</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="estadisticas">Estadísticas</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="presentismo">Presentismo</Nav.Link>
            </Nav.Item>
          </Nav>

          {activeTab === 'mediciones' && (
            <>
              <Row className="my-4">
                <Col md={6}>
                  <Form.Group controlId="filtroTipo">
                    <Form.Label>Filtrar por Tipo de Medición</Form.Label>
                    <Form.Select value={tipoFiltro} onChange={handleTipoFiltroChange}>
                      <option value="">Todos</option>
                      {Object.keys(tipoMedicion).map((key) => (
                        <option key={key} value={key}>
                          {tipoMedicion[key].nombre}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="filtroModo">
                    <Form.Label>Modo</Form.Label>
                    <Form.Select value={modoFiltro} onChange={handleModoFiltroChange}>
                      <option value="ultima">Última Medición</option>
                      <option value="historico">Máximos Históricos</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card>
                    <Card.Header>Progreso por Fecha</Card.Header>
                    <Card.Body>
                      <Line data={prepararDatosGraficoLinea()} options={{ scales: { x: { type: 'time' } } }} />
                    </Card.Body>
                  </Card>
                </Col>
              
                <Col>
                  <Card>
                    <Card.Header>Comparación entre Jugadores</Card.Header>
                    <Card.Body>
                      <Bar data={prepararDatosGraficoBarra()} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card>
                    <Card.Header>Detalle de Mediciones</Card.Header>
                    <Card.Body>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                            <th onClick={() => handleSort('nombre')} style={{ cursor: 'pointer' }}>
                                Jugador {sortConfig.key === 'nombre' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                            <th onClick={() => handleSort('TipoMedicion')} style={{ cursor: 'pointer' }}>
                                Tipo de Medición {sortConfig.key === 'TipoMedicion' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                            <th onClick={() => handleSort('Maximo')} style={{ cursor: 'pointer' }}>
                                Máximo {sortConfig.key === 'Maximo' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                            <th onClick={() => handleSort('Promedio')} style={{ cursor: 'pointer' }}>
                                Promedio {sortConfig.key === 'Promedio' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                            <th onClick={() => handleSort('Fecha')} style={{ cursor: 'pointer' }}>
                                Fecha {sortConfig.key === 'Fecha' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedMediciones().map((med) => (
                            <tr key={`${med.IDJugador}-${med.TipoMedicion}-${med.ID}`}>
                                <td>{`${med.nombre} ${med.apellido}`}</td>
                                <td>{tipoMedicion[med.TipoMedicion]?.nombre}</td>
                                <td>{med.Maximo}</td>
                                <td>{med.Promedio}</td>
                                <td>{med.Fecha}</td>
                            </tr>
                            ))}
                        </tbody>
                        </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          {activeTab === 'estadisticas' && (
            <>
              {loading ? (
                <p>Cargando estadísticas...</p>
                ) : (
                <>
                <Row className="my-4">
                    <Col md={4}>
                        <Form.Group controlId="torneoFiltro">
                            <Form.Label>Filtrar por Torneo</Form.Label>
                            <Form.Select value={torneoFiltro} onChange={handleTorneoFiltroChange}>
                            <option value="">Todos</option>
                            {estadisticas.map((est) => (
                                <option key={est.TorneoID} value={est.TorneoID}>
                                {est.Nombre}
                                </option>
                            ))}
                            </Form.Select>
                        </Form.Group>                        
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="yearFiltro">
                        <Form.Label>Filtrar por Año</Form.Label>
                        <Form.Select
                        value={yearFiltro}
                        onChange={handleYearFiltroChange}
                        >
                        <option value="">Todos</option>
                        {[...new Set(estadisticas.map(est => 
                            est.FechaInicio ? new Date(est.FechaInicio).getFullYear() : null
                        ))].map((year) => (
                            <option key={year} value={year}>
                            {year}
                            </option>
                        ))}
                        </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                        <Form.Check
                            type="checkbox"
                            label="Acumulado Histórico"
                            checked={modoAcumuladoHistorico}
                            onChange={handleModoAcumuladoChange}
                        />
                    </Col>               
                </Row>

   

                {/* Tabla de estadísticas detalladas */}
                <Row className="mt-4">
                    <Col>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Jugador</th>
                            <th>Torneo</th>
                            <th>Fecha</th>
                            <th>Categoria</th>
                            <th>G</th>
                            <th>PA</th>
                            <th>AB</th>
                            <th>R</th>
                            <th>H</th>
                            <th>1B</th>
                            <th>2B</th>
                            <th>3B</th>
                            <th>HR</th>
                            <th>RBI</th>
                            <th>BB</th>
                            <th>SO</th>
                            <th>AVG</th>
                            <th>OBP</th>
                            <th>SLG</th>
                            <th>OPS</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filtrarEstadisticas().map((est) => (
                            <tr key={`${est.JugadorID}-${est.TorneoID}`}>
                            <td>{`${est.nombre} ${est.apellido}`}</td>
                            <td>{est.Nombre}</td>
                            <td>{est.FechaInicio}</td>
                            <td>{est.Categoria}</td>
                            <td>{est.G}</td>
                            <td>{est.PA}</td>
                            <td>{est.AB}</td>
                            <td>{est.R}</td>
                            <td>{est.H}</td>
                            <td>{est.singles}</td>
                            <td>{est.dobles}</td>
                            <td>{est.triples}</td>
                            <td>{est.HR}</td>
                            <td>{est.RBI}</td>
                            <td>{est.BB}</td>
                            <td>{est.SO}</td>
                            <td>{est.AVG}</td>
                            <td>{est.OBP}</td>
                            <td>{est.SLG}</td>
                            <td>{est.OPS}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    </Col>
                </Row>
                </>
            )}
            </>
            )}
            {activeTab === 'presentismo' && (
                <>
                
                </>
            )}
        </>
      )}
      
    </Container>
  );
};

export default EquipoEstadisticas;
