import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Container, Card, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [top10, setTop10] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTop10();
  }, []);

  const fetchTop10 = async () => {
    const token = localStorage.getItem('jwt');  // Obtener el token desde localStorage
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    };
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mediciones/top10`, authHeader);
      setTop10(response.data);
    } catch (error) {
      console.error('Error al cargar el Top 10:', error);
    }
  };

  // Agrupar datos por tipo de medición (pulldown, exitvelo, etc.)
  const agrupadosPorTipo = top10.reduce((acc, jugador) => {
    if (!acc[jugador.tipo]) {
      acc[jugador.tipo] = [];
    }
    acc[jugador.tipo].push(jugador);
    return acc;
  }, {});

  const mediciones = ['pulldown', 'exitvelo', 'bullpen_velocidad', '90 pies', 'BatSpeedMax', 'PeakHandSpeedAvg'];

  return (
    <Container>
      <h1 className="my-4">Dashboard</h1>

      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h5>TOP 10 Mediciones</h5>
          <Button
            variant="primary"
            onClick={() => navigate(`/ver-mas-mediciones`)}
          >
            Ver Más
          </Button>
        </Card.Header>

        <Card.Body>
          <Row>
            {mediciones.map((tipo) => (
              <Col md={4} key={tipo}>
                <Card className="mb-4">
                  <Card.Header>
                    <h5>{tipo.toUpperCase()}</h5>
                  </Card.Header>
                  <Card.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {agrupadosPorTipo[tipo]?.map((jugador, index) => (
                          <tr key={index} style={{ backgroundColor: jugador.genero === 'F' ? '#f8c8dc' : 'transparent' }}>
                            <td>{jugador.nombre} {jugador.apellido}</td>
                            <td>{jugador.valor}</td>
                          </tr>
                        )) || (
                          <tr>
                            <td colSpan="2" className="text-center">No hay datos</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Dashboard;
