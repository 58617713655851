import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns'; // Asegúrate de tener instalada esta librería
import moment from 'moment';
import tipoMedicion from '../utils/tipoMedicion';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const Mediciones = () => {
  const { jugadorId: jugadorIdURL } = useParams();
  const [jugadorId, setJugadorId] = useState(null);
  const [nombreJugador, setNombreJugador] = useState('');
  const [mediciones, setMediciones] = useState([]);
  const [filtroTipo, setFiltroTipo] = useState('');
  const [nuevaMedicion, setNuevaMedicion] = useState({
    Fecha: '',
    TipoMedicion: '',
    Maximo: '',
    Promedio: '',
    Link: '',
  });
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const fetchUserRole = async () => {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: { Authorization: `Bearer ${token}` },
      };
  
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, authHeader);
        setUserRole(response.data.role); // Asumiendo que el rol está en `role`
      } catch (error) {
        console.error('Error al obtener rol del usuario:', error);
      }
    };
  
    fetchUserRole();
  }, []);

  useEffect(() => {
    const fetchJugadorLogueado = async () => {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: { 'Authorization': `Bearer ${token}` },
      };
      try {
        if (!jugadorIdURL) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, authHeader);
          setJugadorId(response.data.Jugador.id);
          setNombreJugador(`${response.data.Jugador.nombre} ${response.data.Jugador.apellido}`);
        } else {
          setJugadorId(jugadorIdURL);
          const jugadorResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores/${jugadorIdURL}`, authHeader);
          setNombreJugador(`${jugadorResponse.data.nombre} ${jugadorResponse.data.apellido}`);
        }
      } catch (error) {
        console.error('Error al obtener datos del jugador logueado:', error);
      }
    };
    fetchJugadorLogueado();
  }, [jugadorIdURL]);

  useEffect(() => {
    const fetchMediciones = async () => {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: { 'Authorization': `Bearer ${token}` },
      };
      if (jugadorId) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mediciones/jugador/${jugadorId}`, authHeader);
          setMediciones(response.data);
        } catch (error) {
          console.error('Error al obtener las mediciones:', error);
        }
      }
    };
    fetchMediciones();
  }, [jugadorId]);

  const handleChange = (e) => {
    setNuevaMedicion({ ...nuevaMedicion, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    const formattedMedicion = {
      ...nuevaMedicion,
      IDJugador: jugadorId,
      TipoMedicion: parseInt(nuevaMedicion.TipoMedicion),
      Maximo: parseFloat(nuevaMedicion.Maximo),
      Promedio: nuevaMedicion.Promedio ? parseFloat(nuevaMedicion.Promedio) : null,
      Fecha: nuevaMedicion.Fecha,
      Link: nuevaMedicion.Link || null,
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/mediciones/crear`, formattedMedicion, authHeader);
      setNuevaMedicion({
        Fecha: '',
        TipoMedicion: '',
        Maximo: '',
        Promedio: '',
        Link: '',
      });
      // Actualizar la lista después de guardar
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mediciones/jugador/${jugadorId}`, authHeader);
      setMediciones(response.data);
    } catch (error) {
      console.error('Error al crear la medición:', error);
    }
  };

  const handleDeleteMedicion = async (id) => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: { Authorization: `Bearer ${token}` },
    };
  
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/mediciones/${id}`, authHeader);
      setMediciones((prev) => prev.filter((med) => med.id !== id)); // Actualiza el estado local
    } catch (error) {
      console.error('Error al eliminar la medición:', error);
    }
  };
  

  const handleFiltroChange = (e) => {
    setFiltroTipo(e.target.value);
  };

  const medicionesFiltradas = Array.isArray(mediciones)
  ? (filtroTipo
      ? mediciones.filter((med) => med.TipoMedicion === parseInt(filtroTipo))
      : mediciones)
  : [];


  // Filtrar valores 0 y ordenar por fecha
    const prepararDatos = (mediciones, tipos) => {
        return mediciones
        .filter(med => tipos.includes(med.TipoMedicion) && med.Maximo > 0) // Excluir valores 0
        .sort((a, b) => new Date(a.Fecha) - new Date(b.Fecha)); // Ordenar por fecha
    };
  
  // Calcular altura ajustada (Altura - 100)
    const calcularAlturaAjustada = (mediciones) => {
        return mediciones
        .filter(med => med.TipoMedicion === 5 && med.Maximo > 0) // Altura (Tipo 5)
        .map(med => ({
            ...med,
            Maximo: med.Maximo - 100, // Restar 100
        }));
    };
    
    

    const datosPulldown = prepararDatos(mediciones, [1]); // Pulldown (Tipo 1)
    const datosExitVelo = prepararDatos(mediciones, [7, 18, 19]); // Exit Velo (Tipos 7, 18, 19)
    const datosAltura = calcularAlturaAjustada(mediciones); // Altura ajustada
    const datosPeso = prepararDatos(mediciones, [4]); // Peso (Tipo 4)

    // Combinar fechas de todas las mediciones relevantes para asegurar consistencia
    const fechas = [...new Set([...datosPulldown, ...datosExitVelo, ...datosAltura, ...datosPeso].map(m => m.Fecha))]
    .sort((a, b) => new Date(a) - new Date(b));

    // Crear dataset para cada métrica
    const crearDataset = (data, label, color) => ({
    label,
    data: fechas.map(fecha => {
        const medicion = data.find(med => med.Fecha === fecha);
        return medicion ? medicion.Maximo : null; // Mostrar null si no hay medición
    }),
    borderColor: color,
    fill: false,
    spanGaps: true, // Conectar puntos aunque haya gaps
    });

  const opcionesGrafico = () => {
  
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'time',
          time: { unit: 'month' },
          title: { display: true, text: 'Fecha' },
        },
        y: {
          ticks: {
            stepSize: 2, // Incrementos de 2 en 2
            callback: (value) => value.toFixed(0), // Mostrar números enteros
          },
          title: {
            display: true,
            text: 'Valor',
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'top',
        },
      },
    };
  }; 

  const dataPulldown = {
    labels: fechas,
    datasets: [
      crearDataset(datosPulldown, 'Pulldown', 'rgba(75,192,192,1)'),
      crearDataset(datosPeso, 'Peso', 'rgba(255,99,132,1)'),
      crearDataset(datosAltura, 'Altura - 100', 'rgba(54,162,235,1)'),
    ],
  };
  
  const dataExitVelo = {
    labels: fechas,
    datasets: [
      crearDataset(datosExitVelo, 'Exit Velo', 'rgba(153,102,255,1)'),
      crearDataset(datosPeso, 'Peso', 'rgba(255,99,132,1)'),
      crearDataset(datosAltura, 'Altura - 100', 'rgba(54,162,235,1)'),
    ],
  };
  

  return (
    <Container>
      <h1>Mediciones de {nombreJugador}</h1>

      <Row className="my-4">
        <Col md={6}>
            <Card>
            <Card.Header>Gráfico Pulldown</Card.Header>
            <Card.Body>
                <Line key={`pulldown-${fechas.join('-')}`} data={dataPulldown} options={opcionesGrafico} />
            </Card.Body>
            </Card>
        </Col>

        <Col md={6}>
            <Card>
            <Card.Header>Gráfico Exit Velo</Card.Header>
            <Card.Body>
                <Line key={`exitvelo-${fechas.join('-')}`} data={dataExitVelo} options={opcionesGrafico} />
            </Card.Body>
            </Card>
        </Col>
      </Row>
      {['coach', 'admin'].includes(userRole) && (  
      <Row>
        <Col>
          <Card>
            <Card.Header>Ingresar Nueva Medición</Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="formFecha">
                      <Form.Label>Fecha</Form.Label>
                      <Form.Control type="date" name="Fecha" value={nuevaMedicion.Fecha} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="formTipoMedicion" className="mt-3">
                      <Form.Label>Tipo de Medición</Form.Label>
                      <Form.Control
                        as="select"
                        name="TipoMedicion"
                        value={nuevaMedicion.TipoMedicion}
                        onChange={handleChange}
                      >
                        <option value="">Selecciona un tipo</option>
                        {Object.keys(tipoMedicion).map((key) => (
                          <option key={key} value={key}>
                            {tipoMedicion[key].nombre}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formMaximo">
                      <Form.Label>Máximo</Form.Label>
                      <Form.Control type="number" name="Maximo" value={nuevaMedicion.Maximo} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="formPromedio" className="mt-3">
                      <Form.Label>Promedio</Form.Label>
                      <Form.Control
                        type="number"
                        name="Promedio"
                        value={nuevaMedicion.Promedio}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formLink">
                      <Form.Label>Link (opcional)</Form.Label>
                      <Form.Control type="text" name="Link" value={nuevaMedicion.Link} onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" onClick={handleSave} className="mt-3">
                  Guardar
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      )}
      <Row className="mt-4">
        <Col>
          <h3>Listado de Mediciones</h3>
          <Form.Group controlId="formFiltroTipo" className="mb-3">
            <Form.Label>Filtrar por tipo</Form.Label>
            <Form.Control as="select" value={filtroTipo} onChange={handleFiltroChange}>
              <option value="">Todos</option>
              {Object.keys(tipoMedicion).map((key) => (
                <option key={key} value={key}>
                  {tipoMedicion[key].nombre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Tipo</th>
                <th>Máximo</th>
                <th>Promedio</th>
                <th>Link</th>
                {['coach', 'admin'].includes(userRole) && <th>Acciones</th>}
              </tr>
            </thead>
            <tbody>
              {medicionesFiltradas.length > 0 ? (
                medicionesFiltradas.map((med) => (
                <tr key={med.id}>
                  <td>{moment(med.Fecha).format('DD-MM-YYYY')}</td>
                  <td>{tipoMedicion[med.TipoMedicion]?.nombre}</td>
                  <td>{med.Maximo}</td>
                  <td>{med.Promedio}</td>
                  <td>
                    {med.Link ? (
                      <a href={med.Link} target="_blank" rel="noopener noreferrer">
                        Ver Video
                      </a>
                    ) : (
                      'Sin video'
                    )}
                  </td>
                  {['coach', 'admin'].includes(userRole) && (
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDeleteMedicion(med.id)}
                      >
                        Eliminar
                      </Button>
                    </td>
                  )}
                </tr>
                ))
                ) : (
                <tr>
                  <td colSpan="5">No hay mediciones disponibles.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Mediciones;
