import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Form, Button, Container, Row, Col } from 'react-bootstrap';

const Categorias = () => {
  const [categorias, setCategorias] = useState([]);
  const [nuevaCategoria, setNuevaCategoria] = useState('');

  useEffect(() => {
    fetchCategorias();
  }, []);

  const fetchCategorias = async () => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categorias`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCategorias(response.data);
    } catch (error) {
      console.error('Error al obtener las categorías:', error);
    }
  };

  const handleCrearCategoria = async () => {
    const token = localStorage.getItem('jwt');
    if (!nuevaCategoria.trim()) {
      alert('El nombre de la categoría es obligatorio.');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/categorias/crear`,
        { Categoria: nuevaCategoria },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setCategorias([...categorias, response.data]);
      setNuevaCategoria('');
    } catch (error) {
      console.error('Error al crear la categoría:', error);
    }
  };

  return (
    <Container className="mt-4">
      <h1>Administración de Categorías</h1>

      {/* Formulario para agregar nueva categoría */}
      <Row className="mb-4">
        <Col md={6}>
          <Form.Group>
            <Form.Label>Nombre de la nueva categoría</Form.Label>
            <Form.Control
              type="text"
              value={nuevaCategoria}
              onChange={(e) => setNuevaCategoria(e.target.value)}
              placeholder="Ingrese el nombre de la categoría"
            />
          </Form.Group>
        </Col>
        <Col md={3} className="d-flex align-items-end">
          <Button variant="primary" onClick={handleCrearCategoria}>
            Crear Categoría
          </Button>
        </Col>
      </Row>

      {/* Tabla de categorías */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody>
          {categorias.map((categoria) => (
            <tr key={categoria.ID}>
              <td>{categoria.ID}</td>
              <td>{categoria.Categoria}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Categorias;
