import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './components/Layout';
import ActiveRoster from './pages/ActiveRoster';
import HistoricRoster from './pages/HistoricRoster';
import JugadorProfile from './pages/JugadorProfile';
import Login from './pages/Login'; // Pantalla de login
import AdminPlayer from './pages/AdminPlayer';
import CargarAranceles from './pages/CargarAranceles';
import ControlAranceles from './pages/ControlAranceles';
import CargaPresentismo from './pages/CargaPresentismo';
import Torneos from './pages/Torneos';
import Partidos from './pages/Partidos';
import MatchJugadores from './pages/MatchJugadores';
import MatchJugadoresPitcheo from './pages/MatchJugadoresPitcheo';
import Dashboard from './pages/Dashboard'; // Importamos el nuevo componente Dashboard
import SesionBullpen from './pages/SesionBullpen';
import SesionBullpenLanzamientos from './pages/SesionBullpenLanzamientos';
import EstadisticasJugador from './pages/EstadisticasJugador';
import TestAuth from './pages/TestAuth';
import EquiposList from './pages/EquiposList';
import ControlPresentismo from './pages/ControlPresentismo';
import BlastMediciones from './pages/BlastMediciones';
import AdminEntrenamiento from './pages/AdminEntrenamiento';
import Entrenamiento from './pages/Entrenamiento';
import Mediciones from './pages/Mediciones';
import CargaMasivaMediciones from './pages/CargaMasivaMediciones';
import PresentismoFecha from './pages/PresentismoFecha';
import Equipos from './pages/Equipos';
import EquipoEstadisticas from './pages/EquipoEstadisticas';
import Estadisticas from './pages/Estadisticas';
import VerMasMediciones from './pages/VerMasMediciones';
import DocumentosUtiles from './pages/DocumentosUtiles';
import Categorias from './pages/Categorias';
import axios from 'axios';

function App() {
  const [user, setUser] = useState(null); // Estado para manejar el usuario autenticado

  useEffect(() => {
    // Aquí puedes verificar si el usuario está autenticado o no (por ejemplo, con un token JWT)
    const fetchUser = async () => {
      const token = localStorage.getItem('jwt');
      if (!token) {
        setUser(null);
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setUser(response.data); // Si se autentica, guarda los datos del usuario
        } else {
          console.error('Error fetching user:', response.statusText);
          setUser(null); // Manejar el error de autenticación
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        setUser(null);
      }
    };
    fetchUser();
  }, []);

  return (
    <Router>
      {/* Si el usuario no está autenticado, muestra la pantalla de login */}
      {!user ? (
        <Login />
      ) : (
        <Layout user={user}>
          <Routes>
            <Route path="/profile" element={<JugadorProfile user={user} />} />
            <Route path="/jugador/:id" element={<JugadorProfile />} />
            <Route path="/admin/players" element={<AdminPlayer />} />
            <Route path="/admin/aranceles" element={<CargarAranceles />} />
            <Route path="/admin/ControlAranceles" element={<ControlAranceles />} />
            <Route path="/admin/ControlPresentismo" element={<ControlPresentismo />} />
            <Route path="/blast" element={<BlastMediciones />} />
            <Route path="/blast/jugador/:jugadorId" element={<BlastMediciones />} />
            <Route path="/admin/presentes" element={<CargaPresentismo />} />
            <Route path="/admin/Torneos" element={<Torneos />} />
            <Route path="/admin/Partidos" element={<Partidos />} />
            <Route path="/match-jugadores" element={<MatchJugadores />} />
            <Route path="/match-jugadores/:torneoID" element={<MatchJugadores />} />
            <Route path="/match-jugadores/:torneoID/:partidoID" element={<MatchJugadores />} />
            <Route path="/match-jugadores-pitcheo" element={<MatchJugadoresPitcheo />} />
            <Route path="/match-jugadores-pitcheo/:torneoID" element={<MatchJugadoresPitcheo />} />
            <Route path="/match-jugadores-pitcheo/:torneoID/:partidoID" element={<MatchJugadoresPitcheo />} />
            <Route path="/estadisticas-jugador/:jugadorID" element={<EstadisticasJugador />} />
            <Route path="/active-roster" element={<ActiveRoster />} />
            <Route path="/historic-roster" element={<HistoricRoster />} />
            <Route path="/bullpen" element={<SesionBullpen />} />
            <Route path="/bullpen/jugador/:jugadorId" element={<SesionBullpen />} />
            <Route path="/bullpen/sesion/:idSesionBullpen" element={<SesionBullpenLanzamientos />} />
            <Route path="/test-auth" element={<TestAuth />} />
            <Route path="/equiposvelez" element={<EquiposList />} />
            <Route path="/entrenamiento/admin" element={<AdminEntrenamiento />} />
            <Route path="/entrenamiento" element={<Entrenamiento />} />
            <Route path="/entrenamiento/:jugadorId" element={<Entrenamiento />} />
            <Route path="/mediciones" element={<Mediciones />} />
            <Route path="/mediciones/:jugadorId" element={<Mediciones />} />
            <Route path="/CargaMasivaMediciones" element={<CargaMasivaMediciones />} />
            <Route path="/presentismofecha" element={<PresentismoFecha />} />
            <Route path="/admin/equiposrivales" element={<Equipos />} />
            <Route path="/equipos/:equipoId/estadisticas" element={<EquipoEstadisticas />} />
            <Route path="/estadisticas" element={<Estadisticas />} />
            <Route path="/Ver-Mas-Mediciones" element={<VerMasMediciones />} />
            <Route path="/documentos-utiles" element={<DocumentosUtiles />} />
            <Route path="/admin/categorias" element={<Categorias />} />
            <Route path="/" element={<Dashboard />} />
          </Routes>
        </Layout>
      )}
    </Router>
  );
}

export default App;
