import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Table, Form, Button, Container, Row, Col, ButtonGroup } from 'react-bootstrap';

const Estadisticas = () => {
  const [estadisticas, setEstadisticas] = useState([]);
  const [columnas, setColumnas] = useState([]);
  const [tipoEstadistica, setTipoEstadistica] = useState('bateo');
  const [periodo, setPeriodo] = useState('torneo'); // torneo, anual, historico
  const [jugadores, setJugadores] = useState([]);
  const [torneos, setTorneos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [filtros, setFiltros] = useState({
    jugadorID: '',
    torneoID: '',
    categoria: '',
    anio: '',
  });
  const [orden, setOrden] = useState({ campo: 'AVG', direccion: 'desc' });

  // Obtener estadísticas
  const fetchEstadisticas = useCallback(async () => {
    const token = localStorage.getItem('jwt');
    const endpoint = `/api/estadisticas/${tipoEstadistica}/${periodo}`;
    const params = { ...filtros };

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });

      // Si hay datos, obtener columnas dinámicamente desde el primer objeto
      if (response.data.length > 0) {
        const columnasDinamicas = Object.keys(response.data[0]);
        setColumnas(columnasDinamicas);
      } else {
        setColumnas([]);
      }

      setEstadisticas(response.data);
    } catch (error) {
      console.error('Error al obtener estadísticas:', error);
    }
  }, [tipoEstadistica, periodo, filtros]);

  // Obtener jugadores
  const fetchJugadores = useCallback(async () => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setJugadores(response.data);
    } catch (error) {
      console.error('Error al obtener jugadores:', error);
    }
  }, []);

  // Obtener torneos
  const fetchTorneos = useCallback(async () => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/torneos`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTorneos(response.data);
    } catch (error) {
      console.error('Error al obtener torneos:', error);
    }
  }, []);

  // Obtener categorías
  const fetchCategorias = useCallback(async () => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categorias`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCategorias(response.data);
    } catch (error) {
      console.error('Error al obtener categorías:', error);
    }
  }, []);

  useEffect(() => {
    fetchEstadisticas();
    fetchJugadores();
    fetchTorneos();
    fetchCategorias();
  }, [fetchEstadisticas, fetchJugadores, fetchTorneos, fetchCategorias]);

  // Actualizar filtros
  const handleFiltroChange = (e) => {
    const { name, value } = e.target;
    setFiltros((prev) => ({ ...prev, [name]: value }));
  };

  // Ordenar columnas (sort dinámico)
  const handleOrden = (campo) => {
    const direccion = orden.campo === campo && orden.direccion === 'asc' ? 'desc' : 'asc';
    const datosOrdenados = [...estadisticas].sort((a, b) => {
      if (a[campo] < b[campo]) return direccion === 'asc' ? -1 : 1;
      if (a[campo] > b[campo]) return direccion === 'asc' ? 1 : -1;
      return 0;
    });
    setEstadisticas(datosOrdenados);
    setOrden({ campo, direccion });
  };

  return (
    <Container className="mt-4">
      <h1>Estadísticas de Jugadores</h1>

      {/* Filtros */}
      <Form className="mb-4">
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Jugador</Form.Label>
              <Form.Select name="jugadorID" onChange={handleFiltroChange}>
                <option value="">Todos</option>
                {jugadores.map((j) => (
                  <option key={j.ID} value={j.ID}>
                    {j.Nombre} {j.Apellido}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group>
              <Form.Label>Torneo</Form.Label>
              <Form.Select name="torneoID" onChange={handleFiltroChange}>
                <option value="">Todos</option>
                {torneos.map((t) => (
                  <option key={t.ID} value={t.ID}>
                    {t.Nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group>
              <Form.Label>Categoría</Form.Label>
              <Form.Select name="categoria" onChange={handleFiltroChange}>
                <option value="">Todas</option>
                {Array.isArray(categorias) ? categorias.map((c) => (
                  <option key={c.ID} value={c.ID}>
                    {c.Nombre}
                  </option>
                )) : <option value="">Cargando categorías...</option>}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group>
              <Form.Label>Año</Form.Label>
              <Form.Control
                type="number"
                name="anio"
                placeholder="Año"
                onChange={handleFiltroChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <ButtonGroup>
              <Button onClick={() => setTipoEstadistica('bateo')} active={tipoEstadistica === 'bateo'}>
                Bateo
              </Button>
              <Button onClick={() => setTipoEstadistica('pitcheo')} active={tipoEstadistica === 'pitcheo'}>
                Pitcheo
              </Button>
            </ButtonGroup>{' '}
            <ButtonGroup>
              <Button onClick={() => setPeriodo('torneo')} active={periodo === 'torneo'}>Torneo</Button>
              <Button onClick={() => setPeriodo('anual')} active={periodo === 'anual'}>Anual</Button>
              <Button onClick={() => setPeriodo('historico')} active={periodo === 'historico'}>Histórico</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Form>

      {/* Tabla de estadísticas */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {columnas.map((col) => (
              <th key={col} onClick={() => handleOrden(col)} style={{ cursor: 'pointer' }}>
                {col} {orden.campo === col ? (orden.direccion === 'asc' ? '▲' : '▼') : ''}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {estadisticas.map((est, index) => (
            <tr key={index}>
              {columnas.map((col) => (
                <td key={col}>{est[col] ?? '-'}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Estadisticas;
