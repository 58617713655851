import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Table, Modal } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import categorias from '../utils/categorias';

const Torneos = () => {
  const [torneos, setTorneos] = useState([]);
  const [nuevoTorneo, setNuevoTorneo] = useState({
    Nombre: '',
    FechaInicio: '',
    FechaFin: '',
    Tipo: 'Local',
    Categoria: '',
    Estado: 'Y'
  });
  const navigate = useNavigate();
  const [editando, setEditando] = useState(null); // Para editar un torneo
  const [selectedFile, setSelectedFile] = useState(null); // Estado para el archivo Excel
  const [showFileUpload, setShowFileUpload] = useState(null); // Controlar la visibilidad del input
  const [filteredTorneos, setFilteredTorneos] = useState([]);
  const [filters, setFilters] = useState({
    Tipo: '',
    Categoria: '',
    Estado: '',
    Año: ''
  });

  const [bateoStats, setBateoStats] = useState([]);
  const [pitcheoStats, setPitcheoStats] = useState([]);
  const [showBateoModal, setShowBateoModal] = useState(false);
  const [showPitcheoModal, setShowPitcheoModal] = useState(false);
  
  useEffect(() => {
    fetchTorneos();
  }, []);

  
  
  const applyFilters = useCallback(() => {
    let filtered = [...torneos];
    if (filters.Tipo) {
      filtered = filtered.filter(torneo => torneo.Tipo === filters.Tipo);
    }
    if (filters.Categoria) {
      filtered = filtered.filter(torneo => torneo.Categoria === filters.Categoria);
    }
    if (filters.Estado) {
      filtered = filtered.filter(torneo => torneo.Estado === filters.Estado);
    }
    if (filters.Año) {
      filtered = filtered.filter(
        torneo => moment(torneo.FechaInicio).year().toString() === filters.Año
      );
    }
    setFilteredTorneos(filtered);
  }, [filters, torneos]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const fetchTorneos = async () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('Token de autenticación no encontrado');
      return;
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/torneos`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const torneosOrdenados = response.data.sort(
        (a, b) => new Date(b.FechaInicio) - new Date(a.FechaInicio)  // Inverso
      );

      setTorneos(torneosOrdenados);
      setFilteredTorneos(torneosOrdenados);
    } catch (error) {
      console.error('Error al obtener los torneos:', error);
    }
  };

  const fetchBateoStats = async (torneoID) => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/estadisticas/bateo/torneo/${torneoID}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('Respuesta de la API:', response.data);
      setBateoStats(Array.isArray(response.data) ? response.data : []);
      setShowBateoModal(true);
    } catch (error) {
      console.error('Error al obtener estadísticas de bateo:', error);
    }
  };

  const fetchPitcheoStats = async (torneoID) => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/estadisticas/pitcheo/torneo/${torneoID}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setPitcheoStats(response.data);
      setShowPitcheoModal(true);
    } catch (error) {
      console.error('Error al obtener estadísticas de pitcheo:', error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };
/*
  // Función memorizada para obtener torneos
  const fetchTorneos = useCallback(async () => {
    const token = localStorage.getItem('jwt'); // Obtener el token de localStorage
    if (!token) {
      console.error('Token de autenticación no encontrado');
      return;
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/torneos`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setTorneos(response.data);
    } catch (error) {
      console.error('Error al obtener los torneos:', error);
    }
  }, []);

  useEffect(() => {
    fetchTorneos();
  }, [fetchTorneos]);
*/
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoTorneo({
      ...nuevoTorneo,
      [name]: value,
    });
  };

  const handleCrearTorneo = async () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('Token de autenticación no encontrado');
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/torneos/crear`, nuevoTorneo, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchTorneos();
      setNuevoTorneo({
        Nombre: '',
        FechaInicio: '',
        FechaFin: '',
        Tipo: 'Local',
        Categoria: '',
        Estado: 'Y'
      });
    } catch (error) {
      console.error('Error al crear el torneo:', error);
    }
  };

  const handleEditarTorneo = async (id) => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('Token de autenticación no encontrado');
      return;
    }
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/torneos/actualizar/${id}`, nuevoTorneo, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchTorneos();
      setEditando(null);
      setNuevoTorneo({
        Nombre: '',
        FechaInicio: '',
        FechaFin: '',
        Tipo: 'Local',
        Categoria: '',
        Estado: 'Y'
      });
    } catch (error) {
      console.error('Error al actualizar el torneo:', error);
    }
  };

  const iniciarEdicion = (torneo) => {
    setNuevoTorneo(torneo);
    setEditando(torneo.id);
  };

  // Manejar la selección del archivo
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // Enviar el archivo Excel al backend
  const handleUploadFile = async (torneoID) => {
    const token = localStorage.getItem('jwt');
    if (!selectedFile || !token) {
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/estadisticasBateo/upload?torneoID=${torneoID}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      alert('Archivo cargado correctamente');
      navigate(`/match-jugadores/${torneoID}`);
    } catch (error) {
      console.error('Error al cargar el archivo:', error);
    }
  };

  const handleUploadFilePitcheo = async (torneoID) => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);
    const token = localStorage.getItem('jwt');

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/estadisticasPitcheo/upload?torneoID=${torneoID}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Archivo cargado correctamente');
      window.location.href = `/match-jugadores-pitcheo/${torneoID}`;
    } catch (error) {
      console.error('Error al cargar el archivo:', error);
    }
  };

/*
  const fetchFilteredTorneos = async () => {
    const token = localStorage.getItem('jwt');
    if (!token) return;
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/torneos`, {
        headers: { Authorization: `Bearer ${token}` },
        params: filters,
      });
      setTorneos(response.data);
    } catch (error) {
      console.error('Error al filtrar torneos:', error);
    }
  };
  */

  return (
    <div className="container mt-4">
      <h1>Administración de Torneos</h1>

      {/* Formulario de creación/edición */}
      <Card className="mb-4">
        <Card.Header>{editando ? 'Editar Torneo' : 'Crear Nuevo Torneo'}</Card.Header>
        <Card.Body>
          <Form>
            {/* Los campos para crear y editar torneos */}
            <Row>
              <Col md={4}>
                <Form.Group controlId="formNombre">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    name="Nombre"
                    value={nuevoTorneo.Nombre}
                    onChange={handleInputChange}
                    placeholder="Nombre del torneo"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formFechaInicio">
                  <Form.Label>Fecha Inicio</Form.Label>
                  <Form.Control
                    type="date"
                    name="FechaInicio"
                    value={nuevoTorneo.FechaInicio}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formFechaFin">
                  <Form.Label>Fecha Fin</Form.Label>
                  <Form.Control
                    type="date"
                    name="FechaFin"
                    value={nuevoTorneo.FechaFin}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={4}>
                <Form.Group controlId="formTipo">
                  <Form.Label>Tipo</Form.Label>
                  <Form.Control
                    as="select"
                    name="Tipo"
                    value={nuevoTorneo.Tipo}
                    onChange={handleInputChange}
                  >
                    <option value="Local">Local</option>
                    <option value="Nacional por equipos">Nacional por equipos</option>
                    <option value="Nacional Selecciones">Nacional Selecciones</option>
                    <option value="Internacional Equipos">Internacional Equipos</option>
                    <option value="Internacional Selecciones">Internacional Selecciones</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formCategoria">
                  <Form.Label>Categoría</Form.Label>
                  <Form.Control
                    as="select"
                    name="Categoria"
                    value={nuevoTorneo.Categoria}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecciona una categoría</option>
                    {Object.entries(categorias).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formEstado">
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    as="select"
                    name="Estado"
                    value={nuevoTorneo.Estado}
                    onChange={handleInputChange}
                  >
                    <option value="Y">Activo</option>
                    <option value="N">Finalizado</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Button
              className="mt-3"
              variant={editando ? 'warning' : 'primary'}
              onClick={editando ? () => handleEditarTorneo(editando) : handleCrearTorneo}
            >
              {editando ? 'Guardar Cambios' : 'Crear Torneo'}
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {/* Filtros */}
      <Card className="mb-4">
        <Card.Header>Filtros</Card.Header>
        <Card.Body>
          <Row>
            <Col md={3}>
              <Form.Group controlId="filterTipo">
                <Form.Label>Tipo</Form.Label>
                <Form.Control
                  as="select"
                  name="Tipo"
                  value={filters.Tipo}
                  onChange={handleFilterChange}
                >
                  <option value="">Todos los tipos</option>
                  <option value="Local">Local</option>
                  <option value="Nacional por equipos">Nacional por equipos</option>
                  <option value="Nacional Selecciones">Nacional Selecciones</option>
                  <option value="Internacional Equipos">Internacional Equipos</option>
                  <option value="Internacional Selecciones">Internacional Selecciones</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="filterCategoria">
                <Form.Label>Categoría</Form.Label>
                <Form.Control
                  as="select"
                  name="Categoria"
                  value={filters.Categoria}
                  onChange={handleFilterChange}
                >
                  <option value="">Todas las categorías</option>
                  {Object.entries(categorias).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="filterEstado">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  as="select"
                  name="Estado"
                  value={filters.Estado}
                  onChange={handleFilterChange}
                >
                  <option value="">Todos</option>
                  <option value="Y">Activo</option>
                  <option value="N">Finalizado</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="filterAño">
                <Form.Label>Año</Form.Label>
                <Form.Control
                  type="number"
                  name="Año"
                  placeholder="2024"
                  value={filters.Año}
                  onChange={handleFilterChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Listado de torneos */}
      <Card>
        <Card.Header>Lista de Torneos</Card.Header>
        <Card.Body>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Fecha Inicio</th>
                <th>Fecha Fin</th>
                <th>Tipo</th>
                <th>Categoría</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filteredTorneos.map((torneo) => (
                <tr key={torneo.id}>
                  <td>{torneo.Nombre}</td>
                  <td>{moment(torneo.FechaInicio).format('DD/MM/YYYY')}</td>
                  <td>{moment(torneo.FechaFin).format('DD/MM/YYYY')}</td>
                  <td>{torneo.Tipo}</td>
                  <td>{categorias[torneo.Categoria]}</td>
                  <td>{torneo.Estado === 'Y' ? 'Activo' : 'Finalizado'}</td>
                  <td>
                    <Button variant="primary" onClick={() => fetchBateoStats(torneo.id)} className="me-2">
                      Ver Bateo
                    </Button>
                    <Button variant="secondary" onClick={() => fetchPitcheoStats(torneo.id)}>
                      Ver Pitcheo
                    </Button>
                    <Button variant="warning" onClick={() => iniciarEdicion(torneo)}>
                      Editar
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => setShowFileUpload(torneo.id)}
                    >
                      Cargar Stats
                    </Button>

                    {/* Mostrar el input y los botones solo si se ha clicado en "Cargar Stats" */}
                    {showFileUpload === torneo.id && (
                      <>
                        <Form.Group controlId={`upload-file-${torneo.id}`} className="mt-2">
                          <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Button
                          className="mt-2"
                          variant="primary"
                          onClick={() => handleUploadFile(torneo.id)}
                        >
                          Cargar Stats Bateo
                        </Button>
                        <Button
                          className="mt-2"
                          variant="secondary"
                          onClick={() => handleUploadFilePitcheo(torneo.id)}
                        >
                          Cargar Stats Pitcheo
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {/* Modal para ver estadísticas de bateo */}
      <Modal show={showBateoModal} onHide={() => setShowBateoModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Estadísticas de Bateo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {bateoStats.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>AB</th>
                  <th>R</th>
                  <th>H</th>
                  <th>BB</th>
                  <th>RBI</th>
                </tr>
              </thead>
              <tbody>
                {bateoStats.map((stat, index) => (
                  <tr key={index}>
                    <td>{stat.nombre}</td>
                    <td>{stat.apellido}</td>
                    <td>{stat.AB}</td>
                    <td>{stat.R}</td>
                    <td>{stat.H}</td>
                    <td>{stat.BB}</td>
                    <td>{stat.RBI}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No hay estadísticas disponibles.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBateoModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para ver estadísticas de pitcheo */}
      <Modal show={showPitcheoModal} onHide={() => setShowPitcheoModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Estadísticas de Pitcheo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {pitcheoStats.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>IP</th>
                  <th>R</th>
                  <th>ER</th>
                  <th>BB</th>
                  <th>K</th>
                </tr>
              </thead>
              <tbody>
                {pitcheoStats.map((stat, index) => (
                  <tr key={index}>
                    <td>{stat.nombre}</td>
                    <td>{stat.apellido}</td>
                    <td>{stat.IP}</td>
                    <td>{stat.R}</td>
                    <td>{stat.ER}</td>
                    <td>{stat.BB}</td>
                    <td>{stat.K}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No hay estadísticas disponibles.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPitcheoModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Torneos;
