import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Table, Form } from 'react-bootstrap';

const VerMasMediciones = () => {
  const [mediciones, setMediciones] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'pulldown', direction: 'desc' });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchMediciones = async () => {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mediciones/todos`, authHeader);
        setMediciones(response.data);
      } catch (error) {
        console.error('Error al obtener las mediciones:', error);
      }
    };

    fetchMediciones();
  }, []);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedMediciones = () => {
    return [...mediciones]
      .filter((item) =>
        `${item.nombre} ${item.apellido}`.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
  };

  return (
    <Container>
      <h1>Mediciones Completas</h1>
      <Form.Control
        type="text"
        placeholder="Buscar jugador..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4"
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => handleSort('nombre')} style={{ cursor: 'pointer' }}>Jugador</th>
            <th onClick={() => handleSort('pulldown')} style={{ cursor: 'pointer' }}>Pulldown</th>
            <th onClick={() => handleSort('exitvelo')} style={{ cursor: 'pointer' }}>Exit Velo</th>
            <th onClick={() => handleSort('bullpen_velocidad')} style={{ cursor: 'pointer' }}>Bullpen Velo</th>
            <th onClick={() => handleSort('BatSpeedMax')} style={{ cursor: 'pointer' }}>Bat Speed</th>
            <th onClick={() => handleSort('PeakHandSpeedAvg')} style={{ cursor: 'pointer' }}>Peak Hand Speed</th>
            <th onClick={() => handleSort('90 pies')} style={{ cursor: 'pointer' }}>90 Pies</th>
          </tr>
        </thead>
        <tbody>
          {sortedMediciones().map((item, index) => (
            <tr key={index}>
              <td>{item.nombre} {item.apellido}</td>
              <td>{item.pulldown || '-'}</td>
              <td>{item.exitvelo || '-'}</td>
              <td>{item.bullpen_velocidad || '-'}</td>
              <td>{item.BatSpeedMax || '-'}</td>
              <td>{item.PeakHandSpeedAvg || '-'}</td>
              <td>{item['90 pies'] || '-'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default VerMasMediciones;
