import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

const BlastMediciones = () => {
  const { jugadorId: jugadorIdURL } = useParams();
  const [jugadorId, setJugadorId] = useState(null);
  const [nombreJugador, setNombreJugador] = useState('');
  const [mediciones, setMediciones] = useState([]);
  const [nuevaMedicion, setNuevaMedicion] = useState({
    Fecha: '',
    Tipo: '',
    Link: '',
    BatSpeedMax: '',
    BatSpeedAvg: '',
    AttackAngleAvg: '',
    PeakHandSpeedAvg: '',
    Bate: ''
  });

  useEffect(() => {
    const fetchJugadorLogueado = async () => {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: { 'Authorization': `Bearer ${token}` },
      };
      try {
        if (!jugadorIdURL) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, authHeader);
          setJugadorId(response.data.Jugador.id);
          setNombreJugador(`${response.data.Jugador.nombre} ${response.data.Jugador.apellido}`);
        } else {
          setJugadorId(jugadorIdURL);
          const jugadorResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores/${jugadorIdURL}`, authHeader);
          setNombreJugador(`${jugadorResponse.data.nombre} ${jugadorResponse.data.apellido}`);
        }
      } catch (error) {
        console.error('Error al obtener datos del jugador logueado:', error);
      }
    };
    fetchJugadorLogueado();
  }, [jugadorIdURL]);

  useEffect(() => {
    const fetchMediciones = async () => {
      const token = localStorage.getItem('jwt');
      if (!token) {
        console.error('Token de autenticación no encontrado');
        return;
      }
  
      const authHeader = {
        headers: { 'Authorization': `Bearer ${token}` },
      };
  
      if (jugadorId) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blast/jugador/${jugadorId}`, authHeader);
          setMediciones(response.data);
        } catch (error) {
          console.error('Error al obtener mediciones de blast:', error);
        }
      }
    };
  
    fetchMediciones();
  }, [jugadorId]);
  

  const handleChange = (e) => {
    setNuevaMedicion({ ...nuevaMedicion, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
  
    const formattedMedicion = {
      ...nuevaMedicion,
      JugadorID: jugadorId,
      Tipo: nuevaMedicion.Tipo || null,
      Link: nuevaMedicion.Link || null,
      BatSpeedMax: nuevaMedicion.BatSpeedMax || null,
      BatSpeedAvg: nuevaMedicion.BatSpeedAvg || null,
      AttackAngleAvg: nuevaMedicion.AttackAngleAvg || null,
      PeakHandSpeedAvg: nuevaMedicion.PeakHandSpeedAvg || null,
      Bate: nuevaMedicion.Bate || null
    };
  
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/blast/crear`, formattedMedicion, authHeader);
      setNuevaMedicion({
        Fecha: '',
        Tipo: '',
        Link: '',
        BatSpeedMax: '',
        BatSpeedAvg: '',
        AttackAngleAvg: '',
        PeakHandSpeedAvg: '',
        Bate: ''
      });
      // Llamar a fetchMediciones después de guardar para actualizar la lista
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blast/jugador/${jugadorId}`, authHeader);
      setMediciones(response.data);
    } catch (error) {
      console.error('Error al crear la medición de blast:', error);
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
  
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/blast/eliminar/${id}`, authHeader);
      setMediciones(mediciones.filter(med => med.ID !== id)); // Actualizar la lista en el estado
    } catch (error) {
      console.error('Error al eliminar medición de blast:', error);
    }
  };
  
  

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  const batSpeedData = {
    labels: mediciones.map(med => moment(med.Fecha).format('YYYY-MM-DD')),
    datasets: [
      {
        label: 'Bat Speed Max',
        data: mediciones.map(med => med.BatSpeedMax),
        borderColor: 'rgba(75,192,192,1)',
        fill: false,
      },
      {
        label: 'Bat Speed Avg',
        data: mediciones.map(med => med.BatSpeedAvg),
        borderColor: 'rgba(153,102,255,1)',
        fill: false,
      },
    ],
  };

  const attackAngleData = {
    labels: mediciones.map(med => moment(med.Fecha).format('YYYY-MM-DD')),
    datasets: [
      {
        label: 'Attack Angle Avg',
        data: mediciones.map(med => med.AttackAngleAvg),
        borderColor: 'rgba(255,159,64,1)',
        fill: false,
      },
    ],
  };

  const handSpeedData = {
    labels: mediciones.map(med => moment(med.Fecha).format('YYYY-MM-DD')),
    datasets: [
      {
        label: 'Peak Hand Speed Avg',
        data: mediciones.map(med => med.PeakHandSpeedAvg),
        borderColor: 'rgba(255,99,132,1)',
        fill: false,
      },
    ],
  };

  return (
    <Container>
      <h1>Mediciones Bateo con Blast de {nombreJugador}</h1>

      <Row className="my-4">
        <Col md={4}>
          <Card>
            <Card.Header>Bat Speed</Card.Header>
            <Card.Body>
              <Line data={batSpeedData} options={chartOptions} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Header>Attack Angle</Card.Header>
            <Card.Body>
              <Line data={attackAngleData} options={chartOptions} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Header>Peak Hand Speed</Card.Header>
            <Card.Body>
              <Line data={handSpeedData} options={chartOptions} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header>Ingresar Nueva Medición</Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="formFecha">
                      <Form.Label>Fecha</Form.Label>
                      <Form.Control type="date" name="Fecha" value={nuevaMedicion.Fecha} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="formTipo" className="mt-3">
                      <Form.Label>Tipo</Form.Label>
                      <Form.Control type="text" name="Tipo" value={nuevaMedicion.Tipo} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="formLink" className="mt-3">
                      <Form.Label>Link</Form.Label>
                      <Form.Control type="text" name="Link" value={nuevaMedicion.Link} onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBatSpeedMax">
                      <Form.Label>Bat Speed Max</Form.Label>
                      <Form.Control type="number" name="BatSpeedMax" value={nuevaMedicion.BatSpeedMax} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="formBatSpeedAvg" className="mt-3">
                      <Form.Label>Bat Speed Avg</Form.Label>
                      <Form.Control type="number" name="BatSpeedAvg" value={nuevaMedicion.BatSpeedAvg} onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formAttackAngleAvg">
                      <Form.Label>Attack Angle Avg</Form.Label>
                      <Form.Control type="number" name="AttackAngleAvg" value={nuevaMedicion.AttackAngleAvg} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="formPeakHandSpeedAvg" className="mt-3">
                      <Form.Label>Peak Hand Speed Avg</Form.Label>
                      <Form.Control type="number" name="PeakHandSpeedAvg" value={nuevaMedicion.PeakHandSpeedAvg} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="formBate" className="mt-3">
                      <Form.Label>Bate</Form.Label>
                      <Form.Control type="text" name="Bate" value={nuevaMedicion.Bate} onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" onClick={handleSave} className="mt-3">Guardar</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h3>Mediciones Anteriores</h3>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Tipo</th>
                <th>Bat Speed Max</th>
                <th>Bat Speed Avg</th>
                <th>Attack Angle Avg</th>
                <th>Peak Hand Speed Avg</th>
                <th>Bate</th>
                <th>Video</th>
              </tr>
            </thead>
            <tbody>
              {mediciones.map(med => (
                <tr key={med.ID}>
                  <td>{moment(med.Fecha).format('DD-MM-YYYY')}</td>
                  <td>{med.Tipo}</td>
                  <td>{med.BatSpeedMax}</td>
                  <td>{med.BatSpeedAvg}</td>
                  <td>{med.AttackAngleAvg}</td>
                  <td>{med.PeakHandSpeedAvg}</td>
                  <td>{med.Bate}</td>
                  <td>{med.Link ? <a href={med.Link} target="_blank" rel="noopener noreferrer">Ver Video</a> : 'Sin video'}</td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(med.ID)}
                    >
                      &times;
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default BlastMediciones;
