import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Table, Card } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';

const PresentismoFecha = () => {
  const [fecha, setFecha] = useState(moment().format('YYYY-MM-DD')); // Fecha actual por defecto
  const [tipo, setTipo] = useState('');
  const [jugadores, setJugadores] = useState([]);
  const [presentismo, setPresentismo] = useState([]);
  const [nuevoPresentismo, setNuevoPresentismo] = useState([]);
  const [loading, setLoading] = useState(false);

  

  const fetchJugadores = async () => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores/active`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedJugadores = response.data.sort((a, b) =>
        a.nombre.localeCompare(b.nombre)
      );
      setJugadores(sortedJugadores);
    } catch (error) {
      console.error('Error al obtener jugadores:', error);
    }
  };

  const fetchPresentismo = React.useCallback(async () => {
    if (!fecha) return alert('Por favor, selecciona una fecha.');
    const token = localStorage.getItem('jwt');
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/presentismo`, {
        params: { fecha, tipo },
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('q trae',response.data);
      setPresentismo(response.data);
    } catch (error) {
      console.error('Error al obtener presentismo:', error);
    } finally {
      setLoading(false);
    }
  }, [fecha, tipo]);

  useEffect(() => {
    fetchJugadores();
    fetchPresentismo(); // Cargar presentes al iniciar
  }, [fetchPresentismo]);

  const handleAddJugador = () => {
    setNuevoPresentismo([...nuevoPresentismo, { JugadorID: '', Tipo: tipo, Fecha: fecha }]);
  };

  const handleRemoveJugador = (index) => {
    const nuevos = [...nuevoPresentismo];
    nuevos.splice(index, 1);
    setNuevoPresentismo(nuevos);
  };

  const handleChangeNuevo = (index, field, value) => {
    const nuevos = [...nuevoPresentismo];
    nuevos[index][field] = value;
    setNuevoPresentismo(nuevos);
  };

  const handleSave = async () => {
    
    const token = localStorage.getItem('jwt');
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/presentismo/registrar`,
        nuevoPresentismo,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('Presentismo guardado correctamente.');
      setNuevoPresentismo([]);
      fetchPresentismo(); // Recargar presentes
    } catch (error) {
      console.error('Error al guardar presentismo:', error);
      alert('Hubo un error al guardar el presentismo.');
    }
  };

  return (
    <div>
      <h1>Gestión de Presentismo</h1>

      {/* Sección de Registro */}
      <Card className="mb-4">
        <Card.Header>Registrar Presentismo</Card.Header>
        <Card.Body>
          <Form>
            <Row className="mb-3">
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Fecha</Form.Label>
                  <Form.Control
                    type="date"
                    value={fecha}
                    onChange={(e) => setFecha(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Tipo</Form.Label>
                  <Form.Control
                    as="select"
                    value={tipo}
                    onChange={(e) => setTipo(e.target.value)}
                  >
                    <option value="">Seleccionar Tipo</option>
                    <option value="Fisico">Físico</option>
                    <option value="Partido">Partido</option>
                    <option value="Entrenamiento en Campo">Entrenamiento en Campo</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="d-flex align-items-end">
                <Button onClick={handleAddJugador}>Agregar Jugador</Button>
              </Col>
            </Row>
          </Form>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Jugador</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {nuevoPresentismo.map((presente, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      as="select"
                      value={presente.JugadorID}
                      onChange={(e) =>
                        handleChangeNuevo(index, 'JugadorID', e.target.value)
                      }
                    >
                      <option value="">Seleccionar Jugador</option>
                      {jugadores.map((jugador) => (
                        <option key={jugador.id} value={jugador.id}>
                          {jugador.nombre} {jugador.apellido}
                        </option>
                      ))}
                    </Form.Control>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveJugador(index)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Button variant="success" onClick={handleSave}>
            Guardar Presentismo
          </Button>
        </Card.Body>
      </Card>

      {/* Sección de Búsqueda */}
      <Card>
        <Card.Header>Buscar Presentismo</Card.Header>
        <Card.Body>
          <Form>
            <Row className="mb-3">
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Fecha</Form.Label>
                  <Form.Control
                    type="date"
                    value={fecha}
                    onChange={(e) => setFecha(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Tipo</Form.Label>
                  <Form.Control
                    as="select"
                    value={tipo}
                    onChange={(e) => setTipo(e.target.value)}
                  >
                    <option value="">Todos</option>
                    <option value="Fisico">Físico</option>
                    <option value="Partido">Partido</option>
                    <option value="Entrenamiento en Campo">
                      Entrenamiento en Campo
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="d-flex align-items-end">
                <Button onClick={fetchPresentismo}>Buscar</Button>
              </Col>
            </Row>
          </Form>

          {loading ? (
            <p>Cargando...</p>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Jugador</th>
                  <th>Fecha</th>
                  <th>Tipo</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(presentismo) ? (presentismo.map((presente, index) => (
                  <tr key={index}>
                    <td>{presente.nombre} {presente.apellido}</td>
                    <td>{moment(presente.Fecha).format('DD-MM-YYYY')}</td>
                    <td>{presente.Tipo}</td>
                  </tr>
                ))
            ) : (
                <p>No hay presentes disponibles.</p>
            )}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PresentismoFecha;
