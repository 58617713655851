import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';

const Equipos = () => {
  const [equipos, setEquipos] = useState([]);
  const [nuevoEquipo, setNuevoEquipo] = useState({ Equipo: '', Region: '' });

  useEffect(() => {
    fetchEquipos();
  }, []);

  const fetchEquipos = async () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('Token de autenticación no encontrado');
      return;
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/equipos`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEquipos(response.data);
    } catch (error) {
      console.error('Error al obtener los equipos:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoEquipo({
      ...nuevoEquipo,
      [name]: value,
    });
  };

  const handleCrearEquipo = async () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('Token de autenticación no encontrado');
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/equipos/crear`, nuevoEquipo, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchEquipos();
      setNuevoEquipo({ Equipo: '', Region: '' });
    } catch (error) {
      console.error('Error al crear el equipo:', error);
    }
  };

  const handleActualizarEstado = async (id, nuevoEstado) => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('Token de autenticación no encontrado');
      return;
    }
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/equipos/actualizar-estado/${id}`,
        { Estado: nuevoEstado },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchEquipos();
    } catch (error) {
      console.error('Error al actualizar el estado del equipo:', error);
    }
  };

  return (
    <div className="container mt-4">
      <h1>Administración de Equipos Rivales</h1>

      {/* Formulario de creación */}
      <Card className="mb-4">
        <Card.Header>Crear Nuevo Equipo</Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formEquipo">
                  <Form.Label>Equipo</Form.Label>
                  <Form.Control
                    type="text"
                    name="Equipo"
                    value={nuevoEquipo.Equipo}
                    onChange={handleInputChange}
                    placeholder="Nombre del equipo"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formRegion">
                  <Form.Label>Región</Form.Label>
                  <Form.Control
                    type="text"
                    name="Region"
                    value={nuevoEquipo.Region}
                    onChange={handleInputChange}
                    placeholder="Región del equipo"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button className="mt-3" variant="primary" onClick={handleCrearEquipo}>
              Crear Equipo
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {/* Listado de equipos */}
      <Card>
        <Card.Header>Lista de Equipos</Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Equipo</th>
                <th>Región</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
            {Array.isArray(equipos) && equipos.map((equipo) => (
                <tr key={equipo.ID}>
                    <td>{equipo.ID}</td>
                    <td>{equipo.Equipo}</td>
                    <td>{equipo.Region || 'Sin región'}</td>
                    <td>{equipo.Estado === 'Y' ? 'Activo' : 'Inactivo'}</td>
                    <td>
                    <Button
                        variant={equipo.Estado === 'Y' ? 'danger' : 'success'}
                        onClick={() => handleActualizarEstado(equipo.ID, equipo.Estado === 'Y' ? 'N' : 'Y')}
                    >
                        {equipo.Estado === 'Y' ? 'Deshabilitar' : 'Habilitar'}
                    </Button>
                    </td>
                </tr>
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Equipos;
