// src/utils/tipoSesion.js
const tiposSesion = [
  { value: 1, label: 'Mound A', intensidad: 'Alta' },
  { value: 2, label: 'Mound B', intensidad: 'Media' },
  { value: 3, label: 'Enfrentamiento', intensidad: 'Alta' },
  { value: 4, label: 'Recovery Short Box', intensidad: 'Media' },
  { value: 5, label: 'Command', intensidad: 'Media' },
  { value: 6, label: 'Partido', intensidad: 'Alta' },
];

export default tiposSesion;