import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import axios from 'axios';
import tipoMedicion from '../utils/tipoMedicion';

const CargaMasivaMediciones = () => {
  const [fecha, setFecha] = useState('');
  const [tipoMedicionSeleccionado, setTipoMedicionSeleccionado] = useState('');
  const [jugadores, setJugadores] = useState([]); // Lista de jugadores
  const [mediciones, setMediciones] = useState([]); // Mediciones en edición
  const [loadingJugadores, setLoadingJugadores] = useState(false);

  useEffect(() => {
    const fetchJugadores = async () => {
      setLoadingJugadores(true);
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        //const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores`, authHeader);
        //setJugadores(response.data);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores/active`, authHeader);

        // Ordenar jugadores alfabéticamente por nombre
        const jugadoresOrdenados = response.data.sort((a, b) => 
            a.nombre.localeCompare(b.nombre)
        );

        setJugadores(jugadoresOrdenados);
      } catch (error) {
        console.error('Error al obtener jugadores:', error);
      } finally {
        setLoadingJugadores(false);
      }
    };

    fetchJugadores();
  }, []);

  const handleAddMedicion = () => {
    setMediciones([
      ...mediciones,
      {
        IDJugador: '',
        Maximo: '',
        Promedio: '',
      },
    ]);
  };

  const handleRemoveMedicion = (index) => {
    const nuevasMediciones = [...mediciones];
    nuevasMediciones.splice(index, 1);
    setMediciones(nuevasMediciones);
  };

  const handleChangeMedicion = (index, field, value) => {
    const nuevasMediciones = [...mediciones];
    nuevasMediciones[index][field] = value;
    setMediciones(nuevasMediciones);
  };

  const handleSaveMediciones = async () => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: { Authorization: `Bearer ${token}` },
    };

    if (!fecha || !tipoMedicionSeleccionado) {
      alert('Por favor, completa la fecha y el tipo de medición.');
      return;
    }

    // Validación según el tipo de medición
    for (let medicion of mediciones) {
      const maximo = parseFloat(medicion.Maximo);

      // Validación para altura (tipo 5)
      if (tipoMedicionSeleccionado === '5' && (maximo < 100 || maximo > 220)) {
        alert(`La altura de ${medicion.IDJugador} debe estar entre 100 y 220 cm.`);
        return;
      }

      // Validación para peso (tipo 4)
      if (tipoMedicionSeleccionado === '4' && (maximo < 20 || maximo > 200)) {
        alert(`El peso de ${medicion.IDJugador} debe estar entre 20 y 200 kg.`);
        return;
      }

      // Validación para los tipos 1, 7, 18, 19
      if (['1', '7', '18', '19'].includes(tipoMedicionSeleccionado) && (maximo < 20 || maximo > 105)) {
        alert(`El valor de la medición debe estar entre 20 y 105 para ${tipoMedicion[tipoMedicionSeleccionado].nombre}.`);
        return;
      }
    }

    // Formatear mediciones para enviar
    const formattedMediciones = mediciones.map((medicion) => ({
      ...medicion,
      Fecha: fecha,
      TipoMedicion: parseInt(tipoMedicionSeleccionado),
    }));

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/mediciones/carga-masiva`, formattedMediciones, authHeader);
      alert('Mediciones guardadas correctamente.');
      setMediciones([]);
    } catch (error) {
      console.error('Error al guardar mediciones:', error);
      alert('Hubo un error al guardar las mediciones.');
    }
  };

  return (
    <Container>
      <h1>Carga Masiva de Mediciones</h1>

      <Row className="mt-4">
        <Col md={4}>
          <Card>
            <Card.Body>
              <Form.Group controlId="formFecha">
                <Form.Label>Fecha</Form.Label>
                <Form.Control type="date" value={fecha} onChange={(e) => setFecha(e.target.value)} />
              </Form.Group>

              <Form.Group controlId="formTipoMedicion" className="mt-3">
                <Form.Label>Tipo de Medición</Form.Label>
                <Form.Control
                  as="select"
                  value={tipoMedicionSeleccionado}
                  onChange={(e) => setTipoMedicionSeleccionado(e.target.value)}
                >
                  <option value="">Selecciona un tipo</option>
                  {Object.keys(tipoMedicion).map((key) => (
                    <option key={key} value={key}>
                      {tipoMedicion[key].nombre}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header>
              Jugadores
              <Button variant="primary" className="float-end" onClick={handleAddMedicion}>
                Agregar Jugador
              </Button>
            </Card.Header>
            <Card.Body>
              {loadingJugadores ? (
                <p>Cargando jugadores...</p>
              ) : (
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Jugador</th>
                      <th>Máximo</th>
                      <th>Promedio</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mediciones.map((medicion, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Control
                            as="select"
                            value={medicion.IDJugador}
                            onChange={(e) => handleChangeMedicion(index, 'IDJugador', e.target.value)}
                          >
                            <option value="">Seleccionar jugador</option>
                            {jugadores.map((jugador) => (
                              <option key={jugador.id} value={jugador.id}>
                                {jugador.nombre} {jugador.apellido}
                              </option>
                            ))}
                          </Form.Control>
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={medicion.Maximo}
                            onChange={(e) => handleChangeMedicion(index, 'Maximo', e.target.value)}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={medicion.Promedio}
                            onChange={(e) => handleChangeMedicion(index, 'Promedio', e.target.value)}
                          />
                        </td>
                        <td>
                          <Button variant="danger" onClick={() => handleRemoveMedicion(index)}>
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Button variant="success" onClick={handleSaveMediciones}>
            Guardar Mediciones
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CargaMasivaMediciones;
