// src/utils/categorias.js

import axios from 'axios';

let categorias = null; // Caché local de las categorías

// Función para cargar las categorías desde el backend
export const fetchCategorias = async () => {
  if (!categorias) {
    try {
      const token = localStorage.getItem('jwt'); // Asegúrate de tener el token de autenticación
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categorias`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      categorias = response.data.reduce((acc, categoria) => {
        acc[categoria.ID] = categoria.Categoria; // Ajusta según el formato de tu respuesta
        return acc;
      }, {});
    } catch (error) {
      console.error('Error al obtener categorías:', error);
      categorias = {}; // En caso de error, devuelve un objeto vacío
    }
  }
  return categorias;
};

export default async function getCategorias() {
  if (!categorias) {
    await fetchCategorias(); // Carga las categorías si aún no están en caché
  }
  return categorias;
}


/*
const categorias = {
    1: 'A1',
    2: 'A2',
    3: 'A3',
    4: 'U23',
    5: 'U18',
    6: 'U17',
    7: 'U15',
    8: 'U14',
    9: 'U13',
    10: 'U12',
    11: 'U10',
    12: 'Femenino',
    13: 'U8',
    14: 'Iniciacion',
    16: 'Manager',
    18: 'Top 6'


  };
  
  export default categorias;
  */