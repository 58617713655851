import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form } from 'react-bootstrap';

const DocumentosUtiles = () => {
  const [documentos, setDocumentos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editando, setEditando] = useState(null);
  const [formData, setFormData] = useState({
    Nombre: '',
    Descripcion: '',
    Link: '',
  });

  useEffect(() => {
    fetchDocumentos();
  }, []);

  const fetchDocumentos = async () => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/documentos`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDocumentos(response.data);
    } catch (error) {
      console.error('Error al obtener documentos:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleGuardar = async () => {
    const token = localStorage.getItem('jwt');
    try {
      if (editando) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/documentos/${editando.ID}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/documentos/crear`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      fetchDocumentos();
      setShowModal(false);
    } catch (error) {
      console.error('Error al guardar documento:', error);
    }
  };

  const handleEliminar = async (id) => {
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar este documento?');
    if (!confirmDelete) return;

    const token = localStorage.getItem('jwt');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/documentos/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchDocumentos();
    } catch (error) {
      console.error('Error al eliminar documento:', error);
    }
  };

  const handleEditar = (documento) => {
    setEditando(documento);
    setFormData({
      Nombre: documento.Nombre,
      Descripcion: documento.Descripcion,
      Link: documento.Link,
    });
    setShowModal(true);
  };

  const handleNuevo = () => {
    setEditando(null);
    setFormData({ Nombre: '', Descripcion: '', Link: '' });
    setShowModal(true);
  };

  return (
    <div className="container mt-4">
      <h1>Administrar Documentos Útiles</h1>
      <Button className="mb-3" onClick={handleNuevo}>
        Agregar Documento
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Enlace</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {documentos.map((doc) => (
            <tr key={doc.ID}>
              <td>{doc.Nombre}</td>
              <td>{doc.Descripcion}</td>
              <td>
                <a href={doc.Link} target="_blank" rel="noopener noreferrer">
                  Ver Documento
                </a>
              </td>
              <td>
                <Button variant="warning" className="me-2" onClick={() => handleEditar(doc)}>
                  Editar
                </Button>
                <Button variant="danger" onClick={() => handleEliminar(doc.ID)}>
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editando ? 'Editar Documento' : 'Nuevo Documento'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="Nombre"
                value={formData.Nombre}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formDescripcion" className="mt-3">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="Descripcion"
                value={formData.Descripcion}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formLink" className="mt-3">
              <Form.Label>Enlace</Form.Label>
              <Form.Control
                type="url"
                name="Link"
                value={formData.Link}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleGuardar}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DocumentosUtiles;
