import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const EstadisticasJugador = () => {
  const { jugadorID } = useParams();
  const [jugador, setJugador] = useState({});
  const [estadisticasActuales, setEstadisticasActuales] = useState([]);
  const [estadisticasHistoricas, setEstadisticasHistoricas] = useState([]);
  const [estadisticasPitcheoActuales, setEstadisticasPitcheoActuales] = useState([]);
  const [estadisticasPitcheoHistoricas, setEstadisticasPitcheoHistoricas] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    // Obtener información del jugador
    const fetchJugador = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores/${jugadorID}`, authHeader);
        setJugador(response.data);
      } catch (error) {
        console.error('Error al obtener el jugador:', error);
      }
    };

    // Obtener estadísticas actuales
    const fetchEstadisticasActuales = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/estadisticas/bateo/actuales/${jugadorID}`, authHeader);
        const ordenadas = response.data.sort((a, b) => new Date(b.Fecha) - new Date(a.Fecha));
        setEstadisticasActuales(ordenadas);
      } catch (error) {
        console.error('Error al obtener estadísticas actuales:', error);
      }
    };

    // Obtener estadísticas históricas
    const fetchEstadisticasHistoricas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/estadisticas/bateo/historico/${jugadorID}`, authHeader);
        const ordenadas = response.data.sort((a, b) => new Date(b.FechaInicio) - new Date(a.FechaInicio));
        setEstadisticasHistoricas(ordenadas);
      } catch (error) {
        console.error('Error al obtener estadísticas históricas:', error);
      }
    };

    // Obtener estadísticas actuales
    const fetchEstadisticasPitcheoActuales = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/estadisticas/pitcheo/actuales/${jugadorID}`, authHeader);
        const ordenadas = response.data.sort((a, b) => new Date(b.Fecha) - new Date(a.Fecha));
        setEstadisticasPitcheoActuales(ordenadas);
      } catch (error) {
        console.error('Error al obtener estadísticas pitcheo actuales:', error);
      }
    };

    // Obtener estadísticas históricas
    const fetchEstadisticasPitcheoHistoricas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/estadisticas/pitcheo/historico/${jugadorID}`, authHeader);
        const ordenadas = response.data.sort((a, b) => new Date(b.FechaInicio) - new Date(a.FechaInicio));
        setEstadisticasPitcheoHistoricas(ordenadas);
      } catch (error) {
        console.error('Error al obtener estadísticas pitcheo históricas:', error);
      }
    };

    fetchJugador();
    fetchEstadisticasActuales();
    fetchEstadisticasHistoricas();
    fetchEstadisticasPitcheoActuales();
    fetchEstadisticasPitcheoHistoricas();
  }, [jugadorID]);

  return (
    <div>
      <h1>Estadísticas de {jugador.nombre} {jugador.apellido}</h1>

      {/* Estadísticas de Bateo */}
      <Card className="my-3">
        <Card.Header>Estadísticas de Bateo</Card.Header>
        <Card.Body>
          <h2>Torneos Actuales</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Categoría</th>
                <th>Equipo Rival</th>
                <th>G</th>
                <th>PA</th>
                <th>AB</th>
                <th>R</th>
                <th>H</th>
                <th>1B</th>
                <th>2B</th>
                <th>3B</th>
                <th>HR</th>
                <th>RBI</th>
                <th>BB</th>
                <th>SO</th>
                <th>AVG</th>
                <th>OBP</th>
                <th>SLG</th>
                <th>OPS</th>
              </tr>
            </thead>
            <tbody>
              {estadisticasActuales.map((estadistica) => (
                <tr key={estadistica.PartidoID}>
                  <td>{estadistica.Fecha}</td>
                  <td>{estadistica.Categoria}</td>
                  <td>{estadistica.EquipoRival}</td>
                  <td>{estadistica.G}</td>
                  <td>{estadistica.PA}</td>
                  <td>{estadistica.AB}</td>
                  <td>{estadistica.R}</td>
                  <td>{estadistica.H}</td>
                  <td>{estadistica.singles}</td>
                  <td>{estadistica.dobles}</td>
                  <td>{estadistica.triples}</td>
                  <td>{estadistica.HR}</td>
                  <td>{estadistica.RBI}</td>
                  <td>{estadistica.BB}</td>
                  <td>{estadistica.SO}</td>
                  <td>{estadistica.AVG}</td>
                  <td>{estadistica.OBP}</td>
                  <td>{estadistica.SLG}</td>
                  <td>{estadistica.OPS}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h2>Histórico</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Torneo</th>
                <th>Fecha</th>
                <th>Categoria</th>
                <th>G</th>
                <th>PA</th>
                <th>AB</th>
                <th>R</th>
                <th>H</th>
                <th>1B</th>
                <th>2B</th>
                <th>3B</th>
                <th>HR</th>
                <th>RBI</th>
                <th>BB</th>
                <th>SO</th>
                <th>AVG</th>
                <th>OBP</th>
                <th>SLG</th>
                <th>OPS</th>
              </tr>
            </thead>
            <tbody>
              {estadisticasHistoricas.map((estadistica) => (
                <tr key={estadistica.TorneoID}>
                  <td>{estadistica.Nombre}</td>
                  <td>{estadistica.FechaInicio}</td>
                  <td>{estadistica.Categoria}</td>
                  <td>{estadistica.G}</td>
                  <td>{estadistica.PA}</td>
                  <td>{estadistica.AB}</td>
                  <td>{estadistica.R}</td>
                  <td>{estadistica.H}</td>
                  <td>{estadistica.singles}</td>
                  <td>{estadistica.dobles}</td>
                  <td>{estadistica.triples}</td>
                  <td>{estadistica.HR}</td>
                  <td>{estadistica.RBI}</td>
                  <td>{estadistica.BB}</td>
                  <td>{estadistica.SO}</td>
                  <td>{estadistica.AVG}</td>
                  <td>{estadistica.OBP}</td>
                  <td>{estadistica.SLG}</td>
                  <td>{estadistica.OPS}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Estadísticas de Pitcheo */}
      <Card className="my-3">
        <Card.Header>Estadísticas de Pitcheo</Card.Header>
        <Card.Body>
          <h2>Torneos Actuales - Pitcheo</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Categoría</th>
                <th>Equipo Rival</th>
                <th>G</th>
                <th>W</th>
                <th>L</th>
                <th>SV</th>
                <th>IP</th>
                <th>BF</th>
                <th>Ball</th>
                <th>Str</th>
                <th>PIT</th>
                <th>R</th>
                <th>ER</th>
                <th>K</th>
                <th>Kc</th>
                <th>Ks</th>
                <th>H</th>
                <th>BB</th>
                <th>HBP</th>
                <th>HR</th>
                <th>GO</th>
                <th>AO</th>
                <th>FPS</th>
                <th>FPB</th>
                <th>ERA</th>
                <th>WHIP</th>
                <th>K/BB</th>
                <th>BB%</th>
                <th>K%</th>
              </tr>
            </thead>
            <tbody>
              {estadisticasPitcheoActuales.map((estadistica) => (
                <tr key={estadistica.PartidoID}>
                  <td>{estadistica.Fecha}</td>
                  <td>{estadistica.Categoria}</td>
                  <td>{estadistica.EquipoRival}</td>
                  <td>{estadistica.G}</td>
                  <td>{estadistica.W}</td>
                  <td>{estadistica.L}</td>
                  <td>{estadistica.SV}</td>
                  <td>{estadistica.IP}</td>
                  <td>{estadistica.BF}</td>
                  <td>{estadistica.Ball}</td>
                  <td>{estadistica.Str}</td>
                  <td>{estadistica.PIT}</td>
                  <td>{estadistica.R}</td>
                  <td>{estadistica.ER}</td>
                  <td>{estadistica.K}</td>
                  <td>{estadistica.Kc}</td>
                  <td>{estadistica.Ks}</td>
                  <td>{estadistica.H}</td>
                  <td>{estadistica.BB}</td>
                  <td>{estadistica.HBP}</td>
                  <td>{estadistica.HR}</td>
                  <td>{estadistica.GO}</td>
                  <td>{estadistica.AO}</td>
                  <td>{estadistica.FPS}</td>
                  <td>{estadistica.FPB}</td>
                  <td>{estadistica.ERA}</td>
                  <td>{estadistica.WHIP}</td>
                  <td>{estadistica.K_B}</td>
                  <td>{estadistica.BB_percentage}</td>
                  <td>{estadistica.K_percentage}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h2>Histórico - Pitcheo</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Torneo</th>
                <th>Fecha</th>
                <th>Categoria</th>
                <th>G</th>
                <th>W</th>
                <th>L</th>
                <th>SV</th>
                <th>IP</th>
                <th>BF</th>
                <th>Ball</th>
                <th>Str</th>
                <th>PIT</th>
                <th>R</th>
                <th>ER</th>
                <th>K</th>
                <th>Kc</th>
                <th>Ks</th>
                <th>H</th>
                <th>BB</th>
                <th>HBP</th>
                <th>HR</th>
                <th>GO</th>
                <th>AO</th>
                <th>FPS</th>
                <th>FPB</th>
                <th>ERA</th>
                <th>WHIP</th>
                <th>K/BB</th>
                <th>BB%</th>
                <th>K%</th>
              </tr>
            </thead>
            <tbody>
              {estadisticasPitcheoHistoricas.map((estadistica) => (
                <tr key={estadistica.TorneoID}>
                  <td>{estadistica.Nombre}</td>
                  <td>{estadistica.FechaInicio}</td>
                  <td>{estadistica.Categoria}</td>
                  <td>{estadistica.G}</td>
                  <td>{estadistica.W}</td>
                  <td>{estadistica.L}</td>
                  <td>{estadistica.SV}</td>
                  <td>{estadistica.IP}</td>
                  <td>{estadistica.BF}</td>
                  <td>{estadistica.Ball}</td>
                  <td>{estadistica.Str}</td>
                  <td>{estadistica.PIT}</td>
                  <td>{estadistica.R}</td>
                  <td>{estadistica.ER}</td>
                  <td>{estadistica.K}</td>
                  <td>{estadistica.Kc}</td>
                  <td>{estadistica.Ks}</td>
                  <td>{estadistica.H}</td>
                  <td>{estadistica.BB}</td>
                  <td>{estadistica.HBP}</td>
                  <td>{estadistica.HR}</td>
                  <td>{estadistica.GO}</td>
                  <td>{estadistica.AO}</td>
                  <td>{estadistica.FPS}</td>
                  <td>{estadistica.FPB}</td>
                  <td>{estadistica.ERA}</td>
                  <td>{estadistica.WHIP}</td>
                  <td>{estadistica.K_BB}</td>
                  <td>{estadistica.BB_percentage}</td>
                  <td>{estadistica.K_percentage}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>

      </Card>
    </div>
  );
};

export default EstadisticasJugador;
